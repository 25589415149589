export const environment = {
  baseUrl: 'https://folio.staging-usc1.aodocs.app/',
  apiUrl: 'api/',
  clientId: '330205576183-58pasnt6m229ferdtg6qhe48eprl8k8c.apps.googleusercontent.com',
  aodocsUrl: 'https://aodocs-staging.altirnao.com/',
  version: '4.2.0',
  production: false,
  firebase: {
    apiKey: 'AIzaSyBWo5_zgbMgKf5nbYrn749NgvNGkBri23k',
    authDomain: 'ao-docs-staging.firebaseapp.com',
    databaseURL: 'https://ao-docs-staging.firebaseio.com',
    projectId: 'ao-docs-staging',
    storageBucket: 'ao-docs-staging.appspot.com',
    messagingSenderId: '563231141203',
    appId: '1:563231141203:web:69fd2c6b041e95f93845a6',
    measurementId: 'G-0RJZXTDXBR',
  }
};
