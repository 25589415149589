import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FolioConfigListComponent} from '../pages/list-configuration/folio-config-list/folio-config-list.component';
import {TemplateConfigListComponent} from '../pages/list-configuration/template-config-list/template-config-list.component';
import {StorageAccountConfigListComponent} from '../pages/list-configuration/storage-account-config-list/storage-account-config-list.component';
import {AuthGuardService} from "../services/guard/auth-guard.service";
import {CustomRolesListComponent} from "../pages/list-configuration/custom-roles-list/custom-roles-list-component";

const routes: Routes = [
  {
    path: 'folio-configurations',
    component: FolioConfigListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'template-configuration',
    component: TemplateConfigListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'storage-account-configuration',
    component: StorageAccountConfigListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'custom-roles-configuration',
    component: CustomRolesListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    redirectTo: 'folio-configurations',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationListRoutingModule {
}
