import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatPaginatorI18nService} from '../services/paginatorI18nService';
import {SharedModule} from './shared.module';
import {FolioConfigDetailsComponent} from "../pages/details-configuration/folio-config-details/folio-config-details.component";
import {StorageAccountConfigDetailsComponent} from "../pages/details-configuration/storage-account-config-details/storage-account-config-details.component";
import {TemplateConfigDetailsComponent} from "../pages/details-configuration/template-config-details/template-config-details.component";
import {ConfigurationDetailsBaseComponent} from '../pages/details-configuration/configuration-details-base.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    ConfigurationDetailsBaseComponent,
    FolioConfigDetailsComponent,
    StorageAccountConfigDetailsComponent,
    TemplateConfigDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      extend: true
    })
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
  ]
})
export class ConfigurationDetailsModule {
}
