import {Component} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor() {
  }

  getUser(): any {
    return JSON.parse(localStorage['user'])
  }

  isSignedIn(): boolean {
    return !!localStorage['user'];
  }

}
