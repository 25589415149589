import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogType} from '../constants/dialog-types';
import {DialogData} from '../models/dialog-data.model';
import {DialogComponent} from '../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  _error: string;

  constructor(private dialog: MatDialog) {
  }

  get errorMessage(): string {
    return this._error;
  }

  set errorMessage(error: string) {
    this._error = error;
  }

  plain(msg: string, title: string): MatDialogRef<DialogComponent, boolean> {
    const dialogData: DialogData = {
      message: msg, title,
      type: DialogType.PLAIN
    };
    return this.open(dialogData);
  }

  info(msg: string, title?: string): MatDialogRef<DialogComponent, boolean> {
    const dialogData: DialogData = {
      message: msg,
      title: title || 'Information',
      type: DialogType.INFO
    };
    return this.open(dialogData);
  }

  warning(msg: string, title?: string): MatDialogRef<DialogComponent, boolean> {
    const dialogData: DialogData = {
      message: msg,
      title: title || 'Warning',
      type: DialogType.WARNING
    };
    return this.open(dialogData);
  }

  error(msg: string, title?: string): MatDialogRef<DialogComponent, boolean> {
    const dialogData: DialogData = {
      message: msg,
      title: title || 'Error',
      type: DialogType.ERROR
    };
    return this.open(dialogData);
  }

  open(dialogData: DialogData): MatDialogRef<DialogComponent, boolean> {
    return this.dialog.open(DialogComponent, {data: dialogData});
  }
}
