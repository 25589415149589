import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigurationListBaseComponent} from '../pages/list-configuration/configuration-list-base.component';
import {ConfigurationListRoutingModule} from './configuration-list-routing.module';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatPaginatorI18nService} from '../services/paginatorI18nService';
import {FolioConfigListComponent} from '../pages/list-configuration/folio-config-list/folio-config-list.component';
import {TemplateConfigListComponent} from '../pages/list-configuration/template-config-list/template-config-list.component';
import {StorageAccountConfigListComponent} from '../pages/list-configuration/storage-account-config-list/storage-account-config-list.component';
import {SharedModule} from './shared.module';
import {TranslateModule} from "@ngx-translate/core";
import {CustomRolesListComponent} from "../pages/list-configuration/custom-roles-list/custom-roles-list-component";

@NgModule({
  declarations: [
    ConfigurationListBaseComponent,
    FolioConfigListComponent,
    CustomRolesListComponent,
    StorageAccountConfigListComponent,
    TemplateConfigListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ConfigurationListRoutingModule,
    TranslateModule.forChild({
      extend: true
    })
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
  ]
})
export class ConfigurationListModule {
}
