import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {ConfigurationDetailsBaseComponent} from '../configuration-details-base.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FolioLibraryConfigModel} from "../../../models/configurations/folio-config.model";
import {StateModel} from "../../../models/configurations/base/state.model";
import {CustomScriptType} from "../../../constants/cs-type-enum";

@Component({
    selector: 'app-details-configuration',
    templateUrl: './folio-config-details.component.html',
    styleUrls: ['../configuration-details-base.component.scss']
})

export class FolioConfigDetailsComponent extends ConfigurationDetailsBaseComponent {

    folioLibrary: FolioLibraryConfigModel;
    stateList: StateModel[];

    defaultAdminsList: string[];
    defaultContributorsList: string[];
    defaultViewersList: string[];
    defaultDocumentManagersList: string[];
    defaultCategoryValueManagersList: string[];
    defaultMaintenanceManagersList: string[];

    serviceColumnsTable = [
        'name',
        'isReadOnly'
    ];

    constructor(@Inject(MAT_DIALOG_DATA) data: any,
                dialogRef: MatDialogRef<FolioConfigDetailsComponent>,
                protected cdr: ChangeDetectorRef) {
        super(data, dialogRef, cdr);

        super.page = 'folio-config';
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.folioLibrary = this.data.folioLibrary;
        if (this.showState()) {
            this.stateList = this.sortedStates();
        }

        this.defaultAdminsList = this.folioLibrary?.defaultAdmins?.length > 0
            ? this.folioLibrary?.defaultAdmins.split(',')
            : [];

        this.defaultContributorsList = this.folioLibrary?.defaultContributors?.length > 0
            ? this.folioLibrary?.defaultContributors?.split(',')
            : [];

        this.defaultViewersList = this.folioLibrary?.defaultViewers?.length > 0
            ? this.folioLibrary?.defaultViewers?.split(',')
            : [];

        this.defaultDocumentManagersList = this.folioLibrary?.defaultDocumentManagers?.length > 0
            ? this.folioLibrary?.defaultDocumentManagers?.split(',')
            : [];

        this.defaultCategoryValueManagersList = this.folioLibrary?.defaultCategoryValueManagers?.length > 0
            ? this.folioLibrary?.defaultCategoryValueManagers?.split(',')
            : [];

        this.defaultMaintenanceManagersList = this.folioLibrary?.defaultMaintenanceManagers?.length > 0
            ? this.folioLibrary?.defaultMaintenanceManagers?.split(',')
            : [];
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
    }

    sortedStates(): StateModel[] {
        return !!this.data.stateList ? this.data.stateList.sort((a, b) => (a.position > b.position) ? 1 : -1) : [];
    }

    showState(): boolean {
        return this.data.folioLibrary.customScriptType != CustomScriptType.WORKFLOW;
    }

}
