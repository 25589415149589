import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ChipsComponent} from '../components/shared/chips/chips.component';
import {SearchInputComponent} from '../components/shared/search-input/search-input.component';
import {MatInputModule} from '@angular/material/input';
import {ExtendedModule, FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDialogModule} from "@angular/material/dialog";
import {MatStepperModule} from "@angular/material/stepper";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {TranslateModule} from "@ngx-translate/core";
import {SuccessSnackbarComponent} from "../components/success-snackbar/success-snackbar.component";
import {DebounceClickDirective} from "../directives/debounce-click.directive";

@NgModule({
  declarations: [
    ChipsComponent,
    SearchInputComponent,
    SuccessSnackbarComponent,
    DebounceClickDirective
  ],
  exports: [
    ChipsComponent,
    SearchInputComponent,
    SuccessSnackbarComponent,
    DebounceClickDirective,

    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    ExtendedModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatStepperModule,
    MatListModule,
    MatMenuModule,
    TranslateModule,
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
  ]
})
export class SharedModule {
}
