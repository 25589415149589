import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {
  }

  errorMessage$ = new Subject<string>();
  refresh$ = new Subject<boolean>();

  refreshAuthComponent(): void {
    this.refresh$.next(true);
  }
}
