import {Injectable} from '@angular/core';
import {AbstractService} from '../abstract.service';
import {ApiVersion} from '../../constants/apis';
import {FolioConfigModel} from '../../models/configurations/folio-config.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LibraryService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  get(libraryId: string): Observable<any> {
    const url = this.toAoDocsUrl()
      + 'library/v1/' + libraryId
      +'?fields=libraryId';
    return this.http.get<any>(url);
  }
}
