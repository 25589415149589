import {Injectable, NgZone} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {UserService} from '../user.service';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class SignOutGuard implements CanActivate {
  constructor(public authService: AuthService,
              public userService: UserService,
              public afAuth: AngularFireAuth,
              public router: Router,
              public ngZone: NgZone) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !localStorage['domain']
      || !localStorage['user']
      || !(!!localStorage['gsi-token'] || !!localStorage['fAuth-token']);
  }
}
