<mat-nav-list fxHide.xs id="menu-navigation-bar" role="navigation">
  <span *ngFor="let item of menu; let first = first;">
    <span *ngIf="item.children && item.children.length > 0">
        <mat-expansion-panel [expanded]="first" class="expansion-menu" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="menu-item">
                  {{'menu.' + item.name|translate}}
                </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item class="menu-item"
             *ngFor="let child of item.children"
             [routerLinkActive]="['is-active', 'is-active-sub']"
             routerLink="{{child.route}}">
            <p class="menu-item">
              {{'menu.' + child.name|translate}}
            </p>
          </a>
        </mat-expansion-panel>
    </span>
    <span *ngIf="!item.children || item.children.length === 0">
      <a mat-list-item class="menu-item"
         [routerLinkActive]="['is-active']"
         routerLink="{{item.route}}">
          <p class="menu-item">
            {{'menu.' + item.name|translate}}
          </p>
      </a>
    </span>
  </span>
</mat-nav-list>
<button mat-icon-button fxHide fxShow.xs
        [matMenuTriggerFor]="dropMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #dropMenu="matMenu">
  <ng-container *ngFor="let item of menu">
    <span *ngIf="!item.children || item.children.length === 0">
      <button mat-menu-item routerLink="{{item.route}}">
        {{'menu.' + item.name|translate}}
      </button>
    </span>
    <span *ngIf="item.children && item.children.length > 0">
      <button mat-menu-item [matMenuTriggerFor]="subMenu">
        {{'menu.' + item.name|translate}}
      </button>
      <mat-menu #subMenu="matMenu">
        <span *ngFor="let child of item.children">
        <button mat-menu-item routerLink="{{child.route}}">
          {{'menu.' + child.name|translate}}
        </button>
        </span>
      </mat-menu>
    </span>
    <mat-divider></mat-divider>
  </ng-container>
</mat-menu>
