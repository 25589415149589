import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatPaginatorI18nService} from '../services/paginatorI18nService';
import {SharedModule} from './shared.module';
import {ConfigurationCreatorBaseComponent} from "../pages/create-configuration/configuration-creator-base.component";
import {
  StorageAccountConfigCreatorComponent
} from '../pages/create-configuration/storage-account-config-creator/storage-account-config-creator.component';
import {
  TemplateConfigCreatorComponent
} from '../pages/create-configuration/template-config-creator/template-config-creator.component';
import {
  FolioConfigCreatorComponent
} from "../pages/create-configuration/folio-config-creator/folio-config-creator.component";
import {MatRadioModule} from "@angular/material/radio";
import {TranslateModule} from "@ngx-translate/core";
import {
  CustomRolesCreatorComponent
} from "../pages/create-configuration/custom-roles-creator/custom-roles-creator.component";

@NgModule({
  declarations: [
    ConfigurationCreatorBaseComponent,
    FolioConfigCreatorComponent,
    CustomRolesCreatorComponent,
    StorageAccountConfigCreatorComponent,
    TemplateConfigCreatorComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MatRadioModule,
        TranslateModule.forChild({
        extend: true
      })
    ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
  ]
})
export class ConfigurationCreatorModule {
}
