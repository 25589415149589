<section class="main-content">
  <h1>{{page + '.list.title'|translate}}</h1>
  <mat-card class="no-padding">
    <section class="filter-section" fxLayout="row" fxLayoutAlign="space-between center">
      <section class="search-input-container" fxFlex="40" fxFlex.lt-md="60"
               fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <app-search-input
          [valueSubject]="searchValueSubject"
          [clearSubject]="searchClearSubject">
        </app-search-input>
      </section>
      <section class="search-input-container"
               fxFlex="60" fxFlex.lt-md="60" fxLayout="row" fxLayoutAlign="end center">
        <button mat-icon-button (click)="openCreateDialog()" class="action-icon"
                matTooltip="{{'tooltips.create'|translate}}">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()" class="action-icon"
                matTooltip="{{'tooltips.refresh'|translate}}">
          <mat-icon>refresh</mat-icon>
        </button>
      </section>
    </section>
    <div class="spinner-container" *ngIf="(loading$|async)">
      <mat-spinner></mat-spinner>
    </div>
    <section fxLayout="row" fxLayoutAlign="start">
      <table mat-table id="{{page + '-list-table'}}" class="mat-elevation-z8"
             [dataSource]="dataSource"
             [hidden]="(loading$|async)">
        <mat-header-row *matHeaderRowDef="getColumnsHeader()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: getColumnsHeader();"></mat-row>

        <ng-container matColumnDef="storageLibraryName">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{page + '.labels.libraryName'|translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{truncateCellContent(element?.folioLibraryName, 25)}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="storageLibraryId">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{page + '.labels.libraryId'|translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{element?.folioLibraryId}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="storageAccounts">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{page + '.labels.storageAccounts'|translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ul class="no-bullets">
              <li *ngFor="let item of element?.storageAccounts">
                {{truncateCellContent(item, 25)}}
              </li>
            </ul>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="storageGcsBucket">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{page + '.labels.gcsBucket'|translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{truncateCellContent(element?.gcsBucket, 25)}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="storageAzureContainer">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{page + '.labels.azureContainer'|translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{truncateCellContent(element?.azureContainer, 25)}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="view">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button mat-icon-button
                    (click)="openDetailsDialog(element)"
                    class="table-button"
                    matTooltip="{{'tooltips.details'|translate}}">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button mat-icon-button class="table-button"
                    (click)="openConfirmStorageAccountDeleteDialog(element)"
                    matTooltip="{{'tooltips.delete'|translate}}">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- Row shown when there is no matching data. -->
        <tr *matNoDataRow class="mat-row">
          <td class="mat-cell" colspan="4">
            {{noDataMessage}}
          </td>
        </tr>
      </table>
    </section>
    <section [hidden]="loading$|async">
      <mat-paginator [pageSizeOptions]="[10,20,30]"></mat-paginator>
    </section>
  </mat-card>
</section>

