import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {FormArray, FormGroup, UntypedFormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationCreatorBaseComponent} from "../configuration-creator-base.component";
import {ConfigurationService} from "../../../services/configuration/configuration.service";
import {FolioService} from "../../../services/configuration/folio.service";
import {FolioConfigModel,} from "../../../models/configurations/folio-config.model";
import {TemplateService} from "../../../services/configuration/template.service";
import {TemplateModel} from "../../../models/configurations/template-config.model";
import {CustomRoleService} from "../../../services/configuration/custom-role.service";
import {
    CustomRole,
    CustomRoleMapping,
    LibraryCustomRoleModel
} from "../../../models/configurations/library-custom-role.model";
import {FieldsModel} from "../../../models/configurations/base/fields.model";
import {BothOrNoneValidator, duplicateValidator} from "../../../validators/custom-validators";
import {MatOptionSelectionChange} from "@angular/material/core";
import {LibraryService} from "../../../services/configuration/library.service";
import {Module} from "../../../constants/modules-enum";

@Component({
    selector: 'app-folio-config-creator',
    templateUrl: './custom-roles-creator.component.html',
    styleUrls: ['../configuration-creator-base.component.scss']
})
export class CustomRolesCreatorComponent extends ConfigurationCreatorBaseComponent {

    public librariesFormGroup: FormGroup;
    public mappingFormGroup: FormGroup;

    public mappingFormArray: FormArray;

    public folioConfigurations: FolioConfigModel[] = [];
    public filteredFolioConfigurations: FolioConfigModel[] = [];

    public templateConfigModels: TemplateModel[] = [];
    public filteredTemplateConfigModels: TemplateModel[] = [];

    public libraryCustomRole: LibraryCustomRoleModel[];
    public filteredCustomRoles: LibraryCustomRoleModel[];

    public filteredPersonFields: FieldsModel[];

    private loadingFolioConfigurations: boolean = true;
    private loadingTemplates: boolean = true;
    private loadingCustomRoles: boolean = true;
    private loadingFieldPerson: boolean = true;

    private selectedTemplateLibraryId: string;
    private selectedFolioConfigLibraryId: string;
    private selectedFolioConfigClassId: string;

    constructor(public configurationService: ConfigurationService,
                protected folioService: FolioService,
                private templateService: TemplateService,
                private customRoleService: CustomRoleService,
                private libraryService: LibraryService,
                dialogRef: MatDialogRef<CustomRolesCreatorComponent>,
                formBuilder: UntypedFormBuilder,
                cdr: ChangeDetectorRef,
                dialogService: DialogService,
                translate: TranslateService,
                ngZone: NgZone) {
        super(dialogRef,
            formBuilder,
            cdr,
            dialogService,
            translate,
            ngZone,
            configurationService);

        super.page = 'custom-roles-config';
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.initForm();
        this.loadFolioConfiguration();
        this.loadTemplateValues();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    private initForm(): void {
        this.initLibrariesFormGroup();
        this.initMappingFormGroup();
    }

    initLibrariesFormGroup(): void {
        this.librariesFormGroup = this.formBuilder.group({
            library: ['', Validators.required],
            template: ['', Validators.required]
        });
    }

    initMappingFormGroup(): void {
        this.mappingFormArray = this.formBuilder.array([
            this.formBuilder.group({
                customRole: [{id: ''}, Validators.required],
                fieldPerson: [[], [Validators.required, Validators.min(1)]]
            }, {validators: BothOrNoneValidator()})
        ], duplicateValidator());

        this.mappingFormGroup = this.formBuilder.group({
            mappingFormArray: this.mappingFormArray
        });
    }

    create(): void {
        this.loadingSubject.next(true);
        const librariesInfo = this.librariesFormGroup.value;
        const mappingFormInfo = this.mappingFormArray.value;

        const customRoleMapping: CustomRoleMapping[] = mappingFormInfo.map(item => {
            return {
                role: {
                    id: item.customRole.id,
                    name: item.customRole.name
                },
                fields: item.fieldPerson.map(i => {
                        return {
                            id: i.id,
                            name: i.displayName
                        }
                    }
                )
            }
        });
        const customRole: CustomRole = {
            folioId: librariesInfo.library.folioLibrary.folioLibraryId,
            folioName: librariesInfo.library.folioLibrary.folioLibraryName,
            templateId: librariesInfo.template.library.id,
            templateName: librariesInfo.template.library.name,
            folioClassId: librariesInfo.library.folioLibrary.folioClassId,
            customRoleMapping: customRoleMapping
        }

        this.customRoleService.save(customRole).subscribe({
            next: () => {
                this.loadingSubject.next(true);
                this.dialogRef.close();
                this.configurationService.creationSubject.next(true);

            },
            error: (error) => {
                this.dialogRef.close();
                this.handleLoadingError(error);
            }
        });
    }


    /*
    *
    * */
    addMapping() {
        this.mappingFormArray.push(
            this.formBuilder.group({
                customRole: [{id: ''}, Validators.required],
                fieldPerson: [[], [Validators.required, Validators.min(1)]]
            })
        );
    }

    removeMapping(index: number) {
        if (this.mappingFormArray.length === 1) {
            return;
        }
        this.mappingFormArray.removeAt(index);
        this.cdr.detectChanges();
    }

    private loadFolioConfiguration(): void {
        this.loadingSubject.next(true);
        this.folioService.list().subscribe({
            next: (resp) => {
                this.loadingFolioConfigurations = false;
                this.loadingSubject.next(this.loadingFolioConfigurations && this.loadingTemplates);

                this.folioConfigurations = resp.items;
                this.filteredFolioConfigurations = this.folioConfigurations;
            },
            error: (error) => {
                this.handleLoadingError(error)
            }
        });
    }

    private loadTemplateValues(): void {
        this.loadingSubject.next(true);
        this.templateService.list().subscribe({
            next: (resp) => {
                this.loadingTemplates = false;
                this.loadingSubject.next(this.loadingFolioConfigurations && this.loadingTemplates);

                this.templateConfigModels = resp.items;
                this.filteredTemplateConfigModels = this.templateConfigModels;
            },
            error: (error) => {
                this.handleLoadingError(error)
            }
        });
    }

    private handleLoadingError(error: ValidationErrors) {
        this.loadingSubject.next(false);
        if (error) {
            console.dir(error);
            this.dialogService.error(
                error?.errors[0]?.message ?? error.message,
                `Error ${error?.code} - ${error?.errors[0]?.reason}`
            )
        }
    }

    displayFolioConfigByLibraryName(value: any) {
        if (value) {
            return value?.folioLibrary.folioLibraryName;
        }
    }

    displayTemplateByName(value: any) {
        if (value) {
            return value?.templateName;
        }
    }

    displayCustomRoleByName(value: any) {
        if (value) {
            return value?.name;
        }
    }

    filterFolioConfigurations(query: string) {
        this.filteredFolioConfigurations =
            this.folioConfigurations.filter(
                item => item.folioLibrary.folioLibraryName.toLowerCase().includes(query)
            );
    }

    filterTemplateConfigurations(query: string) {
        this.filteredTemplateConfigModels =
            this.templateConfigModels.filter(
                item => item.templateName.toLowerCase().includes(query)
            );
    }

    filterCustomRoles(query: string) {
        this.filteredCustomRoles =
            this.libraryCustomRole.filter(
                item => item.name.toLowerCase().includes(query)
            );
    }

    setSelectedTemplateLibraryId(event: MatOptionSelectionChange): void {
        if (event.isUserInput) {
            this.selectedTemplateLibraryId = event.source.value.library.id;
            this.checkIfConfigAlreadyExists();
            this.checkLibraryPermissions(this.selectedTemplateLibraryId, Module.TEMPLATE);
        }
    }

    setSelectedFolioConfigLibraryAndClassId(event: MatOptionSelectionChange): void {
        if (event.isUserInput) {
            this.selectedFolioConfigLibraryId = event.source.value.folioLibrary.folioLibraryId;
            this.selectedFolioConfigClassId = event.source.value.folioLibrary.folioClassId;
            this.checkIfConfigAlreadyExists();
            this.checkLibraryPermissions(this.selectedFolioConfigLibraryId, Module.FOLIO);
        }
    }

    prepareSecondStepData() {
        this.loadCustomRoles();
        this.loadPersonFields();
        this.initMappingFormGroup();
    }

    checkIfConfigAlreadyExists(): void {
        if (!!this.selectedTemplateLibraryId && !!this.selectedFolioConfigLibraryId) {
            const id = this.selectedFolioConfigLibraryId + '-' + this.selectedTemplateLibraryId;
            this.loadingSubject.next(true);
            this.customRoleService.getById(id).subscribe({
                next: (resp) => {
                    this.loadingSubject.next(false);
                    this.librariesFormGroup.setErrors(!!resp ? {'alreadyExists': true} : null);

                },
                error: (error) => this.handleLoadingError(error)
            });
        }
    }


    checkLibraryPermissions(libraryId: string, module: Module): void {
        if (!!libraryId) {
            this.loadingSubject.next(true);
            this.libraryService.get(libraryId).subscribe({
                next: () => {
                    switch (module) {
                        case Module.FOLIO:
                            this.librariesFormGroup.controls['library'].setErrors(null);
                            break;
                        case Module.TEMPLATE:
                            this.librariesFormGroup.controls['template'].setErrors(null);
                            break;
                    }
                    this.loadingSubject.next(false);
                },
                error: () => {
                    switch (module) {
                        case Module.FOLIO:
                            this.librariesFormGroup.controls['library'].setErrors({'folioAccessIssue': true});
                            break;
                        case Module.TEMPLATE:
                            this.librariesFormGroup.controls['template'].setErrors({'templateAccessIssue': true});
                            break;
                    }
                    this.loadingSubject.next(false);
                }
            });
        }
    }

    loadCustomRoles(): void {
        this.loadingSubject.next(true);
        this.customRoleService.getByLibraryId(this.selectedTemplateLibraryId).subscribe({
            next: (resp) => {
                this.loadingCustomRoles = false;
                this.loadingSubject.next(this.loadingCustomRoles && this.loadingFieldPerson);
                this.filteredCustomRoles = resp.items;
            },
            error: (error) => this.handleLoadingError(error)
        });
    }

    private loadPersonFields(): void {
        this.loadingSubject.next(true);
        this.folioService.listFieldListWithId(this.selectedFolioConfigLibraryId, this.selectedFolioConfigClassId).subscribe({
            next: (resp) => {
                this.loadingFieldPerson = false;
                this.loadingSubject.next(this.loadingCustomRoles && this.loadingFieldPerson);
                const fieldsResult = resp.fields;
                this.filteredPersonFields = fieldsResult && fieldsResult.length > 0
                    ? fieldsResult.filter(value => value.type === 'PERSON')
                    : [];
                this.loadingSubject.next(false);
            },
            error: (error) => this.handleLoadingError(error)
        });
    }

}
