import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {DialogService} from '../../../services/dialog.service';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {UserService} from '../../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationListBaseComponent} from '../configuration-list-base.component';
import {
    CustomRolesCreatorComponent
} from "../../create-configuration/custom-roles-creator/custom-roles-creator.component";
import {CustomRoleService} from "../../../services/configuration/custom-role.service";
import {Module} from "../../../constants/modules-enum";
import {CustomRole, CustomRoleMapping} from "../../../models/configurations/library-custom-role.model";
import {CustomRolesDetailsComponent} from "../../details-configuration/custom-roles-details/custom-roles-details.component";

@Component({
    selector: 'custom-roles-config',
    templateUrl: './custom-roles-list-component.html',
    styleUrls: ['../configuration-list-base.component.scss']
})
export class CustomRolesListComponent extends ConfigurationListBaseComponent {

    constructor(protected customRoleService: CustomRoleService,
                configurationService: ConfigurationService,
                ngZone: NgZone,
                userService: UserService,
                dialogService: DialogService,
                dialog: MatDialog,
                cdr: ChangeDetectorRef,
                translate: TranslateService) {
        super(
            configurationService,
            ngZone,
            userService,
            dialogService,
            dialog,
            cdr,
            translate);

        super.page = 'custom-roles-config';

        super.columns = [
            {
                name: 'libraryName',
                show: true
            },
            {
                name: 'libraryId',
                show: true
            },
            {
                name: 'templateName',
                show: true
            },
            {
                name: 'templateId',
                show: true
            },
            {
                name: 'customRoles',
                show: true
            },
            {
                name: 'view',
                show: true
            },
            {
                name: 'delete',
                show: true
            }
        ];
    }

    ngOnInit() {
        super.ngOnInit();
        this.initSubscriptions();
        this.initFilterPredicate();
        this.refresh();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    openCreateDialog(): void {
        super.openDialog(CustomRolesCreatorComponent, {
            width: '720px',
            height: '620px'
        });
    }

    openDetailsDialog(element: CustomRole): void {
      super.openDialogWithElement(CustomRolesDetailsComponent, element, {
        width: '620px',
        height: '600px',
        data: element,
      });
    }

    openConfirmCustomRoleDeleteDialog(customRoleConfig: CustomRole): void {
        const constructCustomMessage = customRoleConfig.folioName +" and "+customRoleConfig.templateName;
        super.openConfirmDeleteDialog(
            constructCustomMessage,
            customRoleConfig.id ,
            'dialog.deleteCustomRolesConfiguration',
            Module.CUSTOM_ROLE
        );

    }

    refresh(): void {
        this.loadValues();
    }

    private initSubscriptions(): void {
        this.deletionSubscription = this.deleteSubject
            .subscribe(() => this.refresh());

        this.creationSubscription = this.configurationService.creationSubject
            .subscribe(() => this.refresh());
    }

    private initFilterPredicate() {
    }

    private loadValues(): void {
        this.loadingSubject.next(true);
        this.customRoleService.list().subscribe({
            next: (resp) => {
                this.loadingSubject.next(false);
                this.dataSource.data = resp.items;
                this.noDataMessage = this.dataSource.data.length > 0 ? '' : this.translate.instant('custom-roles-config.list.noDataYet');
            },
            error: (error) => {
                this.loadingSubject.next(false);
                if (error) {
                    console.dir(error);
                    this.dialogService.error(
                        error?.errors[0]?.message ?? error.message,
                        `Error ${error?.code} - ${error?.errors[0]?.reason}`
                    )
                }
            }
        });
    }

    printCustomRolesMapping(mapping: CustomRoleMapping[]): string{
        return mapping.map(item => item.role.name).join(" | ");
    }

}
