<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">
  {{page + '.creator.title'|translate}}
</h2>
<mat-dialog-content id="folio-creation-dialog-content" class="dialog-padding mat-typography">
  <div class="spinner-container" *ngIf="loading$|async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$|async">
    <mat-horizontal-stepper id="{{page}}-creator-stepper" labelPosition="bottom" linear #stepper>
      <mat-step [stepControl]="basicInfoFormGroup"
                label="{{page + '.creator.steps.1.title'|translate}}">
        <form [formGroup]="basicInfoFormGroup">
          <div fxLayout="row">
            <p class="grey-label label-margin">
              {{page + '.creator.steps.1.description'|translate}}
            </p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.libraryName'|translate}}
              </p>
              <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>
                  {{page + '.labels.libraryName'|translate}}
                </mat-label>
                <input #librarySearchInput
                       type="text"
                       placeholder="{{page + '.labels.selectLibrary'|translate}}"
                       matInput
                       required
                       formControlName="library"
                       (keydown)="checkLibraryState($event)"
                       (keyup)="loadLibraries(librarySearchInput.value)"
                       [readOnly]="libraryReadOnly"
                       [matAutocomplete]="autoLibrary">
                <mat-autocomplete #autoLibrary="matAutocomplete"
                                  (optionSelected)="onSelectionLibrary($event.option.value)"
                                  [displayWith]="displayByName">
                  <mat-option *ngFor="let library of libraries" [value]="library">
                    {{library.name}} - {{library.id}}
                  </mat-option>
                </mat-autocomplete>
                <button mat-button *ngIf="librarySearchInput.value"
                        matSuffix mat-icon-button aria-label="Clear"
                        (click)="clearForm()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.libraryId'|translate}}
              </p>
              <mat-form-field appearance="fill">
                <mat-label>
                  {{page + '.labels.libraryId'|translate}}
                </mat-label>
                <input type="text"
                       matInput
                       readonly
                       required
                       [value]="formLibrary?.id || ''">
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" fxFill>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.className'|translate}}
              </p>
              <mat-form-field appearance="fill">
                <mat-label>
                  Select {{page + '.labels.className'|translate}}
                </mat-label>
                <mat-select formControlName="class"
                            required
                            (selectionChange)="onSelectionClass($event.value)">
                  <mat-option *ngFor="let _class of classList"
                              [value]="_class">
                    {{_class.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="this.isClassSelected" >
                <mat-error *ngIf="this.contributorFieldsList.length === 0">
                  {{page + '.errors.noAuthorizationFields'|translate}}
                </mat-error>
                <mat-error *ngIf="this.formStates.length === 0">
                  {{page + '.errors.noAvailableWorkflows'|translate}}
                </mat-error>
                <mat-error *ngIf="(this.contributorFieldsList.length === 0 || this.formStates.length === 0 )">
                  {{page + '.errors.chooseAnotherClassOrLibrary'|translate}}
                </mat-error>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxFill>
            <mat-radio-group aria-label="Select an option" formControlName="customScriptType">
              <mat-radio-button [value]="customScriptType.UPDATE">
                {{page + '.creator.steps.1.selectUpdateCustomScript'|translate}}
              </mat-radio-button>
              <mat-radio-button [value]="customScriptType.WORKFLOW">
                {{page + '.creator.steps.1.selectWorkflowCustomScript'|translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </form>

        <mat-dialog-actions>
          <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <button mat-button class="blue-label fw-500"
                    matStepperNext
                    [disabled]="basicInfoFormGroup.invalid
                                || this.contributorFieldsList.length === 0
                                || this.formStates.length === 0">
              {{"buttons.next"|translate}}
            </button>
          </div>
        </mat-dialog-actions>
      </mat-step>

      <mat-step [stepControl]="permissionsFormGroup"
                label="{{page + '.creator.steps.2.title'|translate}}">
        <form [formGroup]="permissionsFormGroup">
          <div fxLayout="row">
            <p class="grey-label label-margin sub-title">
              {{page + '.creator.steps.2.description'|translate}}
            </p>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.defaultAdmins'|translate}}
              </p>
              <app-chips [searchSubject]="defaultAdminsSearchSubject"
                         [allItems]="defaultAdminsList"
                         [selectedItems]="defaultAdminsPreselectedItemList"
                         [selectedEventSubject]="defaultAdminsSelectedListSubject"
                         [clearSubject]="defaultAdminsClearSubject"
                         [label]="page + '.labels.selectDefaultAdmins'|translate"
                         [required]="false"
                         [readonly]="false">
              </app-chips>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.defaultContributors'|translate}}
              </p>
              <app-chips [searchSubject]="defaultContributorsSearchSubject"
                         [allItems]="defaultContributorsList"
                         [selectedItems]="defaultContributorsPreselectedItemList"
                         [selectedEventSubject]="defaultContributorsSelectedListSubject"
                         [clearSubject]="defaultContributorsClearSubject"
                         [label]="page + '.labels.selectDefaultContributors'|translate"
                         [required]="false"
                         [readonly]="false">
              </app-chips>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.defaultViewers'|translate}}
              </p>
              <app-chips [searchSubject]="defaultViewersSearchSubject"
                         [allItems]="defaultViewersList"
                         [selectedItems]="defaultViewersPreselectedItemList"
                         [selectedEventSubject]="defaultViewersSelectedListSubject"
                         [clearSubject]="defaultViewersClearSubject"
                         [label]="page + '.labels.selectDefaultViewers'|translate"
                         [required]="false"
                         [readonly]="false">
              </app-chips>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.defaultDocumentManagers'|translate}}
              </p>
              <app-chips [searchSubject]="defaultDocumentManagersSearchSubject"
                         [allItems]="defaultDocumentManagersList"
                         [selectedItems]="defaultDocumentManagersPreselectedItemList"
                         [selectedEventSubject]="defaultDocumentManagersSelectedListSubject"
                         [clearSubject]="defaultDocumentManagersClearSubject"
                         [label]="page + '.labels.selectDefaultDocumentManagers'|translate"
                         [required]="false"
                         [readonly]="false">
              </app-chips>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.defaultCategoryValueManagers'|translate}}
              </p>
              <app-chips [searchSubject]="defaultCategoryValueManagersSearchSubject"
                         [allItems]="defaultCategoryValueManagersList"
                         [selectedItems]="defaultCategoryValueManagersPreselectedItemList"
                         [selectedEventSubject]="defaultCategoryValueManagersSelectedListSubject"
                         [clearSubject]="defaultCategoryValueManagersClearSubject"
                         [label]="page + '.labels.selectDefaultCategoryValueManagers'|translate"
                         [required]="false"
                         [readonly]="false">
              </app-chips>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.defaultMaintenanceManagers'|translate}}
              </p>
              <app-chips [searchSubject]="defaultMaintenanceManagersSearchSubject"
                         [allItems]="defaultMaintenanceManagersList"
                         [selectedItems]="defaultMaintenanceManagersPreselectedItemList"
                         [selectedEventSubject]="defaultMaintenanceManagersSelectedListSubject"
                         [clearSubject]="defaultMaintenanceManagersClearSubject"
                         [label]="page + '.labels.selectDefaultMaintenanceManagers'|translate"
                         [required]="false"
                         [readonly]="false">
              </app-chips>
            </div>
          </div>

          <div fxLayout="row">
            <p class="grey-label label-margin sub-title">
              {{page + '.creator.steps.2.description2'|translate}}
            </p>
          </div>


          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.administratorFields'|translate}}
              </p>
              <mat-form-field>
                <mat-select
                  placeholder="{{page + '.labels.selectAdministratorFields'|translate}}"
                  formControlName="administratorFields"
                  multiple>
                  <mat-option *ngFor="let administrator of administratorsFieldsList"
                              [value]="administrator">
                    {{administrator.displayName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.contributorFields'|translate}} <span class="red"> *</span>
              </p>
              <mat-form-field>
                <mat-select
                  placeholder="{{page + '.labels.selectContributorFields'|translate}}"
                  formControlName="contributorFields"
                  required
                  multiple>
                  <mat-option *ngFor="let contributor of contributorFieldsList"
                              [value]="contributor">
                    {{contributor.displayName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.viewersFields'|translate}}
              </p>
              <mat-form-field>
                <mat-select placeholder="{{page + '.labels.selectViewersFields'|translate}}"
                            formControlName="viewersFields"
                            multiple>
                  <mat-option *ngFor="let viewersFields of viewersFieldsList"
                              [value]="viewersFields">
                    {{viewersFields.displayName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.documentManagersFields'|translate}}
              </p>
              <mat-form-field>
                <mat-select
                  placeholder="{{page + '.labels.selectDocumentManagersFields'|translate}}"
                  formControlName="documentManagersFields"
                  multiple>
                  <mat-option *ngFor="let documentManagersFields of documentManagersFieldsList"
                              [value]="documentManagersFields">
                    {{documentManagersFields.displayName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.categoryValueManagersFieldsTitle'|translate}}
              </p>
              <mat-form-field>
                <mat-select placeholder="{{page + '.labels.categoryValueManagersFieldsPlaceholder'|translate}}"
                            formControlName="categoryValueManagersFields"
                            multiple>
                  <mat-option *ngFor="let categoryValueManagersFields of categoryValueManagersFieldsList"
                              [value]="categoryValueManagersFields">
                    {{categoryValueManagersFields.displayName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.maintenanceManagersFields'|translate}}
              </p>
              <mat-form-field>
                <mat-select
                  placeholder="{{page + '.labels.selectMaintenanceManagersFields'|translate}}"
                  formControlName="maintenanceManagersFields"
                  multiple>
                  <mat-option *ngFor="let maintenanceManagersFields of maintenanceManagersFieldsList"
                              [value]="maintenanceManagersFields">
                    {{maintenanceManagersFields.displayName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>

        <mat-dialog-actions>
          <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <button mat-button matStepperPrevious>
              {{"buttons.previous"|translate}}
            </button>
            <button mat-button class="blue-label fw-500"
                    matStepperNext
                    [disabled]="permissionsFormGroup.invalid"
                    *ngIf="isCustomScriptTypeUpdateSelected()">
              {{"buttons.next"|translate}}
            </button >
            <button appDebounceClick
                    mat-button
                    class="blue-label fw-500"
                    type="button"
                    (click)="isConfigSent = true; create()"
                    [disabled]="isConfigSent || permissionsFormGroup.invalid"
                    *ngIf="!isCustomScriptTypeUpdateSelected()">
              {{"buttons.create"|translate}}
            </button>
          </div>
        </mat-dialog-actions>
      </mat-step>

      <mat-step [stepControl]="statesFormGroup"
                label="{{page + '.creator.steps.3.title'|translate}}"
                *ngIf="isCustomScriptTypeUpdateSelected()">
        <form [formGroup]="statesFormGroup">
          <div formArrayName="states">
            <p class="grey-label label-margin">
              {{page + '.labels.stateList'|translate}}
            </p>
            <div *ngFor="let state of formStates.controls; let i=index">
              <div [formGroupName]="i">
                <div fxLayout="row" fxLayoutAlign="space-between">
                  <div fxLayout="column" fxFlex="40">
                    <mat-label>
                      {{state.value.name}}
                    </mat-label>
                  </div>
                  <div fxLayout="column" fxFlex="60">
                    <mat-form-field appearance="fill">
                      <mat-label>
                        {{page + '.labels.selectState'|translate}}
                      </mat-label>
                      <mat-select formControlName="isReadOnly" required>
                        <mat-option *ngFor="let stateValue of configurationService.stateValue"
                                    [value]="stateValue.isReadOnly">
                          {{stateValue.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <mat-dialog-actions>
          <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <button mat-button matStepperPrevious>
              {{"buttons.previous"|translate}}
            </button>

            <button appDebounceClick
                    mat-button
                    class="blue-label fw-500"
                    type="button"
                    (click)="isConfigSent = true; create()"
                    [disabled]="isConfigSent || statesFormGroup.invalid">
              {{"buttons.create"|translate}}
            </button>
          </div>
        </mat-dialog-actions>

      </mat-step>
    </mat-horizontal-stepper>
  </section>
</mat-dialog-content>
