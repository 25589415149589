import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {ConfigurationDetailsBaseComponent} from "../configuration-details-base.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CustomRole, CustomRoleMapping} from "../../../models/configurations/library-custom-role.model";

@Component({
  selector: 'app-custom-roles-details',
  templateUrl: './custom-roles-details.component.html',
  styleUrls: ['../configuration-details-base.component.scss']
})
export class CustomRolesDetailsComponent extends ConfigurationDetailsBaseComponent {

  customRolesConfig: CustomRole;
  splitCustomRoles: CustomRoleMapping[][];

  constructor(@Inject(MAT_DIALOG_DATA) data: any,
              dialogRef: MatDialogRef<CustomRolesDetailsComponent>,
              protected cdr: ChangeDetectorRef) {
    super(data, dialogRef, cdr);

    super.page = 'custom-roles-config';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customRolesConfig = this.data;
    this.split();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }


  split(): void {
    var chunk_size = 2;
    var arr = this.customRolesConfig.customRoleMapping;
    this.splitCustomRoles = arr.map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
    }).filter(function (e) {
      return e;
    });
  }
}
