import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {UserService} from '../../../services/user.service';
import {DialogService} from '../../../services/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationListBaseComponent} from '../configuration-list-base.component';
import {TemplateModel} from '../../../models/configurations/template-config.model';
import {TemplateConfigCreatorComponent} from '../../create-configuration/template-config-creator/template-config-creator.component';
import {TemplateConfigDetailsComponent} from '../../details-configuration/template-config-details/template-config-details.component';
import {TemplateService} from '../../../services/configuration/template.service';
import {Module} from '../../../constants/modules-enum';

@Component({
  selector: 'app-template-config',
  templateUrl: './template-config-list.component.html',
  styleUrls: ['../configuration-list-base.component.scss']
})
export class TemplateConfigListComponent extends ConfigurationListBaseComponent {

  constructor(private templateService: TemplateService,
              configurationService: ConfigurationService,
              ngZone: NgZone,
              userService: UserService,
              dialogService: DialogService,
              dialog: MatDialog,
              cdr: ChangeDetectorRef,
              translate: TranslateService) {
    super(
      configurationService,
      ngZone,
      userService,
      dialogService,
      dialog,
      cdr,
      translate);

    super.page = 'template-config';

    super.columns = [
      {
        name: 'templateName',
        show: true
      },
      {
        name: 'templateLibraryId',
        show: true
      },
      {
        name: 'templateLibraryName',
        show: true
      },
      {
        name: 'view',
        show: true
      },
      {
        name: 'delete',
        show: true
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
    this.initFilterPredicate();
    this.refresh();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  openCreateDialog() {
    super.openDialog(TemplateConfigCreatorComponent, {
      width: '680px',
      height: '400px',
    });
  }

  openDetailsDialog(element) {
    super.openDialogWithElement(TemplateConfigDetailsComponent, element, {
      width: '680px',
      height: '300px',
      data: element,
    });
  }

  openConfirmTemplateDeleteDialog(templateModel: TemplateModel) {
    super.openConfirmDeleteDialog(
      templateModel.templateName,
      templateModel.templateName,
      'dialog.deleteTemplateConfiguration',
      Module.TEMPLATE);
  }

  refresh(): void {
    this.loadValues();
  }

  private initSubscriptions(): void {
    this.deletionSubscription = this.deleteSubject
      .subscribe(() => this.refresh());

    this.creationSubscription = this.configurationService.creationSubject
      .subscribe(() => this.refresh());
  }

  private initFilterPredicate() {
    this.dataSource.filterPredicate =
      (data: TemplateModel, filter: string): boolean =>
        data.templateName?.trim().toLowerCase().includes(filter) ||
        data.library?.id?.trim().toLowerCase().includes(filter) ||
        data.library?.name?.trim().toLowerCase().includes(filter);
  }

  private loadValues(): void {
    this.loadingSubject.next(true);
    this.templateService.list().subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.dataSource.data = resp.items;
        this.noDataMessage = this.dataSource.data.length > 0 ? '' :  this.translate.instant('template-config.list.noDataYet');
      },
      error: (error) => {
        this.loadingSubject.next(false);
        if (error) {
          console.dir(error);
          this.dialogService.error(
            error?.errors[0]?.message ?? error.message,
            `Error ${error?.code} - ${error?.errors[0]?.reason}`
          )
        }
      }
    });
  }

}
