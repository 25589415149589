<section class="main-content">
  <section class="done-section" *ngIf="done;else mainLoginSection">
    <h3>Your credentials have been stored correctly</h3>
  </section>
  <ng-template #mainLoginSection>
    <section class="login-section">
      <div class="spinner-container" *ngIf="googleLoading || microsoftLoading">
        <mat-spinner></mat-spinner>
      </div>
        <section class="login-section" [fxHide] ="googleLoading || microsoftLoading" >
          <h3>Store your credentials</h3>
          <section>
            <form [formGroup]="form">
              <div class="form-group">
                <input
                  formControlName="domain"
                  type="text"
                  class="form-control domain-input"
                  id="domain"
                  aria-describedby="domainHelp"
                  placeholder="Enter Domain"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  formControlName="secret"
                  type="text"
                  class="form-control domain-input"
                  id="secret"
                  aria-describedby="secretHelp"
                  placeholder="Enter Secret key"
                  required
                />
              </div>
            </form>
          </section>
          <div class="mat-elevation-z2 sign-in-button aodocs-sign-in-btn">
            <div
              class="button-header"
              (click)="form.valid ? microsoftAuth() : null"
              [class.disabled]="!form.valid || microsoftLoading"
            >
              <img alt="logo" class="logo-size" src="../../../assets/img/microsoft_icon.svg"/>
              <span>Sign in with Microsoft</span>
            </div>
          </div>
          Or
          <div class="mat-elevation-z2 sign-in-button">
            <ao-google-login
              [clientId]="clientId"
              [scopes]="scopes"
              (loginSuccess)="googleLoginSuccess($event)"
              (loginError)="loginError()"
              [disabled]="isSignInDisabled()"
              display-mode="content"
              authentication-mode="code"
              id="gLogin"
            >
              <div
                class="button-header"
                (click)="isSignInDisabled() ? null : googleAuth()"
                [class.disabled]="isSignInDisabled()"
              >
                <mat-progress-spinner *ngIf="googleLoading" mode="indeterminate" [diameter]="24"></mat-progress-spinner>
                <img alt="logo" class="logo-size" src="../../../assets/img/google_icon.svg"/>
                <span>Sign in with Google</span>
              </div>
            </ao-google-login>
          </div>
        </section>

    </section>
  </ng-template>
</section>
