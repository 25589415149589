import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {ConfigurationDetailsBaseComponent} from '../configuration-details-base.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TemplateModel} from "../../../models/configurations/template-config.model";
import {LibraryModel} from "../../../models/configurations/base/library.model";

@Component({
  selector: 'app-template-config-details',
  templateUrl: './template-config-details.component.html',
  styleUrls: ['../configuration-details-base.component.scss']

})
export class TemplateConfigDetailsComponent extends ConfigurationDetailsBaseComponent {

  template: TemplateModel;
  library: LibraryModel;

  constructor(@Inject(MAT_DIALOG_DATA)  data: any,
              dialogRef: MatDialogRef<TemplateConfigDetailsComponent>,
              protected cdr: ChangeDetectorRef) {
    super(data, dialogRef, cdr);

    super.page = 'template-config';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.template = this.data;
    this.library = this.data.library;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
