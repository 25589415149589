import {Injectable} from '@angular/core';
import {AbstractService} from '../abstract.service';
import {ApiVersion} from '../../constants/apis';
import {StorageAccountConfigModel} from '../../models/configurations/storage-account-config.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageAccountService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  create(storageAdmin: StorageAccountConfigModel): Observable<any> {
    const url = this.toUrl('folio', 'storageAccountsCreate', ApiVersion.V1);
    return this.http.post<any>(url, storageAdmin);
  }

  list(): Observable<any> {
    const url = this.toUrl('folio', 'storageAccountsList', ApiVersion.V1);
    return this.http.get<any>(url);
  }

   fetchStorageAdminsList(): Observable<any> {
    const url = this.toAoDocsUrl() + 'domain/v1/storageadmins';
    return this.http.get<any>(url)
  }
}
