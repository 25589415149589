<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">
  {{page + '.creator.title'|translate}}
</h2>
<mat-dialog-content id="template-creation-dialog-content" class="dialog-padding mat-typography">
  <div class="spinner-container" *ngIf="loading$|async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$|async">
    <form [formGroup]="templateForm">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex="100">
          <p class="grey-label label-margin">
            {{page + '.labels.templateName'|translate}}
          </p>
          <mat-form-field appearance="fill">
            <mat-label>
              {{page + '.labels.selectTemplateName'|translate}}
            </mat-label>
            <input matInput formControlName="templateName"
                   type="text"
                   autocomplete="off"
                   required>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex="48">
          <p class="grey-label label-margin">
            {{page + '.labels.libraryName'|translate}}
          </p>
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>
              {{page + '.labels.selectLibraryName'|translate}}
            </mat-label>
            <input #librarySearchInput
                   type="text"
                   matInput
                   required
                   formControlName="library"
                   [matAutocomplete]="autoLibrary">
            <mat-autocomplete #autoLibrary="matAutocomplete"
                              [displayWith]="displayByName">
              <mat-option *ngFor="let library of libraries" [value]="library">
                {{library.name}} - {{library.id}}
              </mat-option>
            </mat-autocomplete>
            <button mat-button *ngIf="librarySearchInput.value"
                    matSuffix mat-icon-button aria-label="Clear"
                    (click)="templateForm.reset()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="48">
          <p class="grey-label label-margin">
            {{page + '.labels.libraryId'|translate}}
          </p>
          <mat-form-field appearance="fill" fxFlex="48">
            <mat-label>
              {{page + '.labels.libraryId'|translate}}
            </mat-label>
            <input type="text"
                   matInput
                   required
                   readonly
                   [value]="templateForm.get('library').value?.id || ''">
          </mat-form-field>
        </div>
      </div>

      <div mat-dialog-actions>
        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
          <button appDebounceClick
                  mat-button
                  class="blue-label fw-500"
                  type="button"
                  (click)="isConfigSent = true; create()"
                  [disabled]="isConfigSent || templateForm.invalid || !templateForm.get('library').value?.id">
            {{"buttons.create"|translate}}
          </button>
        </div>
      </div>
    </form>
  </section>
</mat-dialog-content>
