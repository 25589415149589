import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {DialogService} from '../../../services/dialog.service';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {UserService} from '../../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FolioConfigModel} from '../../../models/configurations/folio-config.model';
import {ConfigurationListBaseComponent} from '../configuration-list-base.component';
import {FolioConfigCreatorComponent} from '../../create-configuration/folio-config-creator/folio-config-creator.component';
import {FolioConfigDetailsComponent} from '../../details-configuration/folio-config-details/folio-config-details.component';
import {FolioService} from '../../../services/configuration/folio.service';
import {Module} from '../../../constants/modules-enum';

@Component({
  selector: 'app-folio-config',
  templateUrl: './folio-config-list.component.html',
  styleUrls: ['../configuration-list-base.component.scss']
})
export class FolioConfigListComponent extends ConfigurationListBaseComponent {

  constructor(protected folioService: FolioService,
              configurationService: ConfigurationService,
              ngZone: NgZone,
              userService: UserService,
              dialogService: DialogService,
              dialog: MatDialog,
              cdr: ChangeDetectorRef,
              translate: TranslateService) {
    super(
      configurationService,
      ngZone,
      userService,
      dialogService,
      dialog,
      cdr,
      translate);

    super.page = 'folio-config';

    super.columns = [
      {
        name: 'libraryName',
        show: true
      },
      {
        name: 'libraryId',
        show: true
      },
      {
        name: 'className',
        show: true
      },
      {
        name: 'view',
        show: true
      },
      {
        name: 'delete',
        show: true
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
    this.initFilterPredicate();
    this.refresh();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  openCreateDialog(): void {
    super.openDialog(FolioConfigCreatorComponent, {
      width: '680px',
      height: '620px'
    });
  }

  openDetailsDialog(element): void {
    super.openDialogWithElement(FolioConfigDetailsComponent, element, {
      width: '680px',
      height: 'auto',
      data: element,
    });
  }

  openConfirmFolioConfigDeleteDialog(folioConfigModel: FolioConfigModel): void {
    super.openConfirmDeleteDialog(
      folioConfigModel.folioLibrary.folioLibraryName,
      folioConfigModel.folioLibrary.folioLibraryId,
      'dialog.deleteFolioConfiguration',
      Module.FOLIO
    );
  }

  refresh(): void {
    this.loadValues();
  }

  private initSubscriptions(): void {
    this.deletionSubscription = this.deleteSubject
      .subscribe(() => this.refresh());

    this.creationSubscription = this.configurationService.creationSubject
      .subscribe(() => this.refresh());
  }

  private initFilterPredicate() {
    this.dataSource.filterPredicate =
      (data: FolioConfigModel, filter: string): boolean => {
        if (data && data.folioLibrary) {
          return data.folioLibrary.folioLibraryId?.trim().toLowerCase().includes(filter) ||
            data.folioLibrary.folioLibraryName?.trim().toLowerCase().includes(filter) ||
            data.folioLibrary.folioClassName?.trim().toLowerCase().includes(filter);
        }
        return false;
      }
  }

  private loadValues(): void {
    this.loadingSubject.next(true);
    this.folioService.list().subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.dataSource.data = resp.items;
        this.noDataMessage = this.dataSource.data.length > 0 ? '' : this.translate.instant('folio-config.list.noDataYet');
      },
      error: (error) => {
        this.loadingSubject.next(false);
        if (error) {
          console.dir(error);
          this.dialogService.error(
            error?.errors[0]?.message ?? error.message,
            `Error ${error?.code} - ${error?.errors[0]?.reason}`
          )
        }
      }
    });
  }

}
