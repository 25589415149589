<section class="generic-dialog">
  <header>
    <h1 mat-dialog-title>{{title}}</h1>
  </header>
  <div mat-dialog-content>
    <p class="{{type}}">{{message}}</p>
    <p>{{description}}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
    <button *ngIf="isChoiceBased()" [mat-dialog-close]="false" class="grey" mat-button mat-dialog-close>Cancel</button>
    <button [mat-dialog-close]="true" class="confirm-action-btn" mat-button mat-dialog-close>OK</button>
  </div>
</section>
