import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthContext} from './models/auth-context.model';
import {Scope} from './constants/scopes';
import {Apis} from './constants/apis';
import {MenuComponent} from './components/menu/menu.component';
import {HeaderComponent} from './components/header/header.component';
import {ProfileComponent} from './components/header/profile/profile.component';
import {HelpComponent} from './components/header/help/help.component';
import {DomainViewerComponent} from './components/header/domain-viewer/domain-viewer.component';
import {AboutDialogComponent} from './components/header/about-dialog/about-dialog.component';
import {DialogComponent} from './components/dialog/dialog.component';
import {ErrorComponent} from './components/error/error.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SharedModule} from './modules/shared.module';
import {ConfigurationListModule} from './modules/configuration-list.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ConfigurationCreatorModule} from './modules/configuration-creator.module';
import {ConfigurationDetailsModule} from './modules/configuration-details.module';
import {AutocompleteComponent} from './components/autocomplete/autocomplete.component';
import {MatStepperModule} from '@angular/material/stepper';
import {AuthTokenInterceptor} from './interceptors/request.interceptor';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { TechnicalSignInComponent } from './pages/sign-in/technical-sign-in/technical-sign-in.component';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireModule} from '@angular/fire/compat';
import '@aodocs/authentication';
import { CustomRolesDetailsComponent } from './pages/details-configuration/custom-roles-details/custom-roles-details.component';

@NgModule({
  declarations: [
    AboutDialogComponent,
    AppComponent,
    SignInComponent,
    DialogComponent,
    DomainViewerComponent,
    ErrorComponent,
    HeaderComponent,
    HelpComponent,
    MenuComponent,
    ProfileComponent,
    AutocompleteComponent,
    SignInComponent,
    TechnicalSignInComponent,
    CustomRolesDetailsComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    ConfigurationListModule,
    ConfigurationCreatorModule,
    ConfigurationDetailsModule,

    SharedModule,

    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatDialogModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule
  ],
  providers: [
    {
      provide: AuthContext,
      useValue: {
        apis: [Apis.AUTH2, Apis.CLIENT].join(':'),
        clientId: environment.clientId,
        scope: [Scope.EMAIL, Scope.PROFILE]
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    }
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}
