import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {DialogService} from "../../services/dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {AODocsRole} from '../../constants/aodocs-roles-enum';
import {LibraryModel} from "../../models/configurations/base/library.model";

@Component({
  template: ``,
  styleUrls: ['./configuration-creator-base.component.scss']
})
export class ConfigurationCreatorBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public page: string;
  public basicInfoFormGroup: UntypedFormGroup;
  public permissionsFormGroup: UntypedFormGroup;
  public statesFormGroup: UntypedFormGroup;
  public libraries: LibraryModel[];

  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(public dialogRef: MatDialogRef<ConfigurationCreatorBaseComponent>,
              protected formBuilder: UntypedFormBuilder,
              protected cdr: ChangeDetectorRef,
              protected dialogService: DialogService,
              protected translate: TranslateService,
              protected ngZone: NgZone,
              protected configurationService: ConfigurationService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  public fetchLibraries(query: string, limit: number): void {
    this.configurationService.searchLibraries(query, AODocsRole.ADMIN, limit).subscribe({
      next: (resp) => {
        this.libraries = resp.libraries;
      },
      error: (error) => {
        if (error) {
          console.dir(error);
          this.dialogService.error(
            error?.errors[0]?.message ?? error.message,
            `Error ${error?.code} - ${error?.errors[0]?.reason}`
          )
        }
      }
    });
  }

  public displayByName(value: any) {
    if (value) {
      return value?.name;
    }
  }

}
