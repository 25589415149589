import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  template: ``
})
export class ConfigurationDetailsBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public page: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              protected dialogRef: MatDialogRef<ConfigurationDetailsBaseComponent>,
              protected cdr: ChangeDetectorRef) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

}
