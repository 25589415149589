import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatInput} from '@angular/material/input';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Input() valueSubject: Subject<string>;
  @Input() clearSubject: Subject<boolean>;
  @ViewChild(MatInput) input: MatInput;

  constructor() {
  }

  ngOnInit(): void {
    this.clearSubject.subscribe(() => {
      this.clear();
    });
  }

  changed(value: string): void {
    if (value.length === 0 || value.length >= 3) {
      this.valueSubject.next(value);
    }
  }

  clear(): void {
    this.input.value = '';
  }
}
