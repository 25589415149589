import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {DialogService} from '../../services/dialog.service';
import {MatPaginator} from '@angular/material/paginator';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {UserService} from '../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ComponentType} from '@angular/cdk/overlay';
import {TranslateService} from '@ngx-translate/core';
import {Module} from '../../constants/modules-enum';

@Component({
  template: ``,
  styleUrls: ['./configuration-list-base.component.scss']
})

export class ConfigurationListBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  protected loadingSubject = new BehaviorSubject<boolean>(false);

  protected columns: any[];
  protected noDataMessage: string;

  public loading$ = this.loadingSubject.asObservable();
  public page: string;

  protected creationSubscription: Subscription;
  protected deletionSubscription: Subscription;

  public dataSource: MatTableDataSource<any>;
  public searchValueSubject: Subject<string>;
  public searchClearSubject: Subject<boolean>;
  public deleteSubject: Subject<boolean>;

  constructor(protected configurationService: ConfigurationService,
              protected ngZone: NgZone,
              protected userService: UserService,
              protected dialogService: DialogService,
              protected dialog: MatDialog,
              protected cdr: ChangeDetectorRef,
              protected translate: TranslateService) {
    this.dataSource = new MatTableDataSource<any>();
    this.searchValueSubject = new Subject<string>();
    this.searchClearSubject = new Subject();
    this.deleteSubject = new Subject<boolean>();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initPaginator();
    this.initSearch();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  initPaginator(): void {
    this.dataSource.paginator = this.paginator;
  }

  initSearch(): void {
    this.searchValueSubject
      .subscribe((value) => {
        if (this.dataSource.data.length > 0) {
          this.dataSource.filter = value.trim().toLowerCase();
          if (this.dataSource.filteredData.length === 0) {
            this.noDataMessage = this.translate.instant(
              'pagination.noDataFound',
              {
                value: value
              });
          }
        }
      });
  }

  getColumnsHeader(): string[] {
    return this.columns
      .filter(column => column.show)
      .map(column => column.name);
  }

  openDialog(component: ComponentType<any>, config: any): void {
    this.dialog.open(component, config);
  }

  openDialogWithElement(component: ComponentType<any>, element: any, config: any): void {
    this.dialog.open(component, config);
  }

  delete(id: string, module: Module): void {
    this.configurationService.prepareDelete(id,module).subscribe({
      next: (resp) => {
        console.log(resp);
        this.deleteSubject.next(true);
      },
      error: (error) => {
        if (error) {
          console.dir(error);
          this.dialogService.error(
            error?.errors[0]?.message ?? error.message,
            `Error ${error?.code} - ${error?.errors[0]?.reason}`
          )
        }
      }
    });
  }

  openConfirmDeleteDialog(deleteTargetObjectName: string, deleteTargetObjectId: string, keyMessage: string, module: Module): void {
    const dialogTitle = this.translate.instant('dialog.confirmation');
    const deleteMessage = this.translate.instant(
      keyMessage,
      {
        deleteTargetObject: deleteTargetObjectName
      });

    this.dialogService.plain(deleteMessage, dialogTitle)
      .afterClosed()
      .subscribe((choice) => {
        if (choice) {
          this.delete(deleteTargetObjectId, module);
        }
      });
  }

  unsubscribe(): void {
    this.searchValueSubject.unsubscribe();
    this.searchClearSubject.unsubscribe();
    this.deleteSubject.unsubscribe();
    this.creationSubscription.unsubscribe();
    this.deletionSubscription.unsubscribe();
  }

  //#endregion

}

