<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">
  {{page + '.details.title'|translate}}
</h2>
<mat-dialog-content id="details-dialog-content" class="dialog-padding mat-typography">
  <section>
    <div fxLayout="row">
      <p class="grey-label fw-900 label-margin">
        {{page + '.creator.steps.1.title'|translate}}
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.libraryName'|translate}}
        </p>
        <p>
          {{folioLibrary?.folioLibraryName}}
        </p>
      </div>
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.libraryId'|translate}}
        </p>
        <p>
          {{folioLibrary?.folioLibraryId}}
        </p>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.className'|translate}}
        </p>
        <p>
          {{folioLibrary?.folioClassName}}
        </p>
      </div>
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.customScriptType'|translate}}
        </p>
        <p>
          {{folioLibrary?.customScriptType}}
        </p>
      </div>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section>
    <div fxLayout="row">
      <p class="grey-label fw-900 label-margin">
        {{page + '.creator.steps.2.title2'|translate}}
      </p>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="50" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.defaultContributors'|translate}}
        </p>
        <ul>
          <li *ngFor="let field of defaultContributorsList">
            {{field}}
          </li>
        </ul>
      </div>
      <div fxLayout="column" fxFlex="50" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.defaultAdmins'|translate}}
        </p>
        <ul>
          <li *ngFor="let field of defaultAdminsList">
            {{field}}
          </li>
        </ul>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="50" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.defaultViewers'|translate}}
        </p>
        <ul>
          <li *ngFor="let field of defaultViewersList">
            {{field}}
          </li>
        </ul>
      </div>
      <div fxLayout="column" fxFlex="50" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.defaultDocumentManagers'|translate}}
        </p>
        <ul>
          <li *ngFor="let field of defaultDocumentManagersList">
            {{field}}
          </li>
        </ul>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="50" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.defaultCategoryValueManagers'|translate}}
        </p>
        <ul>
          <li *ngFor="let field of defaultCategoryValueManagersList">
            {{field}}
          </li>
        </ul>
      </div>
      <div fxLayout="column" fxFlex="50" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.defaultMaintenanceManagers'|translate}}
        </p>
        <ul>
          <li *ngFor="let field of defaultMaintenanceManagersList">
            {{field}}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>

  <section>
    <div fxLayout="row">
      <p class="grey-label fw-900 label-margin">
        {{page + '.creator.steps.2.title3'|translate}}
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="100" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.contributorFields'|translate}}
        </p>
        <ul>
          <li *ngFor="let contributorField of folioLibrary?.contributorFields">
            {{contributorField}}
          </li>
        </ul>
      </div>
      <div fxLayout="column" fxFlex="100" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.administratorFields'|translate}}
        </p>
        <ul>
          <li *ngFor="let administratorField of folioLibrary?.administratorFields">
            {{administratorField}}
          </li>
        </ul>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="100" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.viewersFields'|translate}}
        </p>
        <ul>
          <li *ngFor="let viewersField of folioLibrary?.viewersFields">
            {{viewersField}}
          </li>
        </ul>
      </div>
      <div fxLayout="column" fxFlex="100" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.documentManagersFields'|translate}}
        </p>
        <ul>
          <li *ngFor="let documentManagersFields of folioLibrary?.documentManagersFields">
            {{documentManagersFields}}
          </li>
        </ul>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="100" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.categoryValueManagersFieldsTitle'|translate}}
        </p>
        <ul>
          <li *ngFor="let categoryValueManagersFields of folioLibrary?.categoryValueManagersFields">
            {{categoryValueManagersFields}}
          </li>
        </ul>
      </div>
      <div fxLayout="column" fxFlex="100" class="list-container">
        <p class="grey-label label-small-margin">
          {{page + '.labels.maintenanceManagersFields'|translate}}
        </p>
        <ul>
          <li *ngFor="let maintenanceManagersFields of folioLibrary?.maintenanceManagersFields">
            {{maintenanceManagersFields}}
          </li>
        </ul>
      </div>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section *ngIf="showState()">
    <div fxLayout="row">
      <p class="grey-label fw-900 label-margin">
        {{page + '.creator.steps.3.title'|translate}}
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start">
      <table mat-table [dataSource]="stateList">
        <tr mat-header-row *matHeaderRowDef="serviceColumnsTable"></tr>
        <tr mat-row *matRowDef="let row; columns: serviceColumnsTable;"></tr>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            State name
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="isReadOnly">
          <th mat-header-cell *matHeaderCellDef>
            Is Read Only
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.isReadOnly}}
          </td>
        </ng-container>
      </table>
    </div>
  </section>
</mat-dialog-content>
