import {Component, Input} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent  {
  @Input() profile: any;

  constructor(private authService: AuthService,
              private userService: UserService,
              public router: Router,
              public errorService: ErrorService) {
  }

  signOut(): void {
    this.userService.user = null;
    this.authService.signOut();
  }

  getProfilePicture(): string {
    return  this.profile && this.profile.thumbnailPictureUrl ? this.profile.thumbnailPictureUrl : '../../../../assets/img/dummy-user.png'
  }

  getProfileEmail(): string {
    return this.profile && this.profile.email ? this.profile.email : ''
  }

  getProfileName(): string {
    return this.profile && this.profile.name ? this.profile.name : ''
  }
}
