import {Injectable} from '@angular/core';
import {AbstractService} from '../abstract.service';
import {ApiVersion} from '../../constants/apis';
import {FolioConfigModel} from '../../models/configurations/folio-config.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FolioService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  create(folioConfigModel: FolioConfigModel): Observable<any> {
    const url = this.toUrl('folio', 'folioCreate', ApiVersion.V1);
    return this.http.post<any>(url, folioConfigModel);
  }

  list(): Observable<any> {
    const url = this.toUrl('folio', 'folioList', ApiVersion.V1);
    return this.http.get<any>(url);
  }

  fetchFieldsList(libraryId: string, classId: string): Observable<any> {
    const url = this.toAoDocsUrl()
      + 'documentType/v1/libraries/' + libraryId + '/'
      + 'documentTypes/' + classId
      + '?fields=fields(displayName%2Cid%2Ctype)';
    return this.http.get<any>(url);
  }

   fetchStateList(libraryId: string, classId: string): Observable<any> {
    const url = this.toAoDocsUrl()
      + 'workflow/v2beta1/workflows?libId=' + libraryId
      + '&docClassId=' + classId + '&fields=workflows%2Fstates%2Fname';
    return this.http.get<any>(url);
  }

  listFieldListWithId(libraryId: string, classId: string): Observable<any> {
    const url = this.toAoDocsUrl() +
      'documentType/v1/libraries/'+libraryId+'/documentTypes/'+classId+'?fields=fields(displayName%2Cid%2Ctype)%2Cid%2Cname'
    return this.http.get<any>(url);
  }
}
