import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {User} from '../models/user.model';
import {Observable, Subject, Subscription} from 'rxjs';
import {AuthService} from './auth.service';
import {DialogService} from './dialog.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ErrorService} from './error.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService {

  public userSubject = new Subject<User>();
  private user$: User;
  private authSubscription: Subscription = new Subscription();

  constructor(private authService: AuthService,
              private errorService: ErrorService,
              private dialogService: DialogService,
              private router: Router,
              private http: HttpClient) {
    super();
    this.authSubscription = this.authService.profile$.subscribe((profile) => {
      if (profile) {
        this.getCurrentUser().subscribe({
          next: async (resp) => {
            await this.router.navigate(['folio-configurations']);
            this.user = resp;
          },
          error: (error) => {
            this.user = null;
            const [exception, ...message] = error.error.error.message.split(':');
            this.errorService.errorMessage$.next(message != '' ? message.join(' ') : exception);
            this.authService.signOut();
            this.errorService.refreshAuthComponent();
          }
        });
      } else {
        this.user = null;
      }
    })
  }

  get user(): User {
    return this.user$;
  }

  set user(value: User) {
    this.user$ = value;
    this.userSubject.next(value);
  }

  getCurrentUser(): Observable<User> {
    const url = this.toUrl('folio', 'currentUser', ApiVersion.V1);
    return this.http.get<any>(url);
  }
}
