<button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">
    {{page + '.creator.title'|translate}}
</h2>
<mat-dialog-content id="folio-creation-dialog-content" class="dialog-padding mat-typography">
    <div class="spinner-container" *ngIf="loading$|async">
        <mat-spinner></mat-spinner>
    </div>
    <section [hidden]="loading$|async">
        <mat-horizontal-stepper id="{{page}}-creator-stepper" labelPosition="bottom" linear #stepper>
            <mat-step [stepControl]="librariesFormGroup"
                      label="{{page + '.creator.steps.1.title'|translate}}">
                <form [formGroup]="librariesFormGroup">
                    <div fxLayout="row">
                        <p class="grey-label label-margin">{{page + '.creator.steps.1.description'|translate}}</p>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between">

                        <div fxLayout="column" fxFlex="48">
                            <p class="grey-label label-margin">{{page + '.labels.folioLibrary'|translate}}</p>
                            <mat-form-field appearance="fill">
                                <mat-label>{{page + '.labels.folioLibrary'|translate}}</mat-label>
                                <input #librarySearchInput
                                       type="text"
                                       formControlName="library"
                                       matInput
                                       (keyup)="filterFolioConfigurations(librarySearchInput.value)"
                                       [attr.disabled]="librariesFormGroup.controls['template'].errors?.templateAccessIssue ? true : null"
                                       [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete"
                                                  [displayWith]="displayFolioConfigByLibraryName">
                                    <mat-option *ngFor="let folioConfiguration of filteredFolioConfigurations"
                                                [value]="folioConfiguration"
                                                (onSelectionChange)="setSelectedFolioConfigLibraryAndClassId($event)">
                                        {{folioConfiguration.folioLibrary.folioLibraryName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxFlex="48">
                            <p class="grey-label label-margin">{{page + '.labels.templateLibrary'|translate}}</p>
                            <mat-form-field appearance="fill">
                                <mat-label>{{page + '.labels.templateLibrary'|translate}}</mat-label>
                                <input #templateSearchInput
                                       type="text"
                                       formControlName="template"
                                       matInput
                                       [attr.disabled]="librariesFormGroup.controls['library'].errors?.folioAccessIssue ? true : null"
                                       (keyup)="filterTemplateConfigurations(templateSearchInput.value)"
                                       [matAutocomplete]="autoTemplate">
                                <mat-autocomplete #autoTemplate="matAutocomplete"
                                                  [displayWith]="displayTemplateByName">
                                    <mat-option *ngFor="let templateConfigModel of filteredTemplateConfigModels"
                                                [value]="templateConfigModel"
                                                (onSelectionChange)="setSelectedTemplateLibraryId($event)">
                                        {{templateConfigModel.templateName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="red" fxLayoutAlign="center"
                         *ngIf="!!librariesFormGroup.errors?.alreadyExists">
                        <small>{{page + '.errors.configExists'|translate}}</small>
                    </div>
                  <div class="red" fxLayoutAlign="center"
                       *ngIf="!!librariesFormGroup.controls['library'].errors?.folioAccessIssue">
                    <small>{{page + '.errors.noAccessFolio'|translate}}</small>
                  </div>
                  <div class="red" fxLayoutAlign="center"
                       *ngIf="!!librariesFormGroup.controls['template'].errors?.templateAccessIssue">
                    <small>{{page + '.errors.noAccessTemplate'|translate}}</small>
                  </div>
                </form>
                <mat-dialog-actions>
                    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                        <button mat-button class="blue-label fw-500"
                                matStepperNext
                                (click)="prepareSecondStepData()"
                                [disabled]="!librariesFormGroup.valid">
                            {{"buttons.next"|translate}}
                        </button>
                    </div>
                </mat-dialog-actions>
            </mat-step>

            <mat-step [stepControl]="mappingFormGroup"
                      label="{{page + '.creator.steps.2.title'|translate}}">
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="45">
                        <p class="grey-label label-margin">{{page + '.creator.steps.2.labels.customRoles'|translate}}</p>
                    </div>
                    <div fxLayout="column" fxFlex="45">
                        <p class="grey-label label-margin">{{page + '.creator.steps.2.labels.PersonFields'|translate}}</p>
                    </div>
                </div>

                <form [formGroup]="mappingFormGroup">
                    <div formArrayName="mappingFormArray" fxFlex="100">
                        <div *ngFor="let data of mappingFormArray.controls; let i = index; last as isLast;"
                             fxLayout="row" fxLayoutAlign="space-between center"
                             [formGroupName]="i">
                            <div fxLayout="column" fxFlex="40">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{page + '.labels.customRoles'|translate}}</mat-label>
                                    <input #customRolesSearchInput
                                           type="text"
                                           formControlName="customRole"
                                           matInput
                                           (keyup)="filterCustomRoles(customRolesSearchInput.value)"
                                           [matAutocomplete]="autoCustomRole">
                                    <mat-autocomplete #autoCustomRole="matAutocomplete"
                                                      [displayWith]="displayCustomRoleByName">
                                        <mat-option *ngFor="let customRole of filteredCustomRoles"
                                                    [value]="customRole">
                                            {{customRole.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" fxFlex="40">
                                <mat-form-field>
                                    <mat-label>{{page + '.labels.folioPersonFields'|translate}}</mat-label>
                                    <mat-select
                                            formControlName="fieldPerson"
                                            required
                                            multiple>
                                        <mat-option *ngFor="let filteredPersonField of filteredPersonFields"
                                                    [value]="filteredPersonField">
                                            {{filteredPersonField.displayName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxFlex.lt-sm="100" fxLayoutAlign.lt-sm="end center">
                                <button [style.visibility]="isLast ? 'visible' : 'hidden' " mat-icon-button
                                        aria-label="Add metadata" type="button"
                                        (click)="addMapping()"
                                        [disabled]="!data.valid || mappingFormArray.errors !== null">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <button mat-icon-button aria-label="Remove metadata" type="button"
                                        (click)="removeMapping(i)">
                                    <mat-icon>remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <mat-dialog-actions>
                        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                            <button mat-button matStepperPrevious>
                                {{"buttons.previous"|translate}}
                            </button>

                            <button appDebounceClick
                                    mat-button
                                    class="blue-label fw-500"
                                    type="button"
                                    [disabled]="!mappingFormGroup.valid"
                                    (click)="create()"
                            >
                                {{"buttons.create"|translate}}
                            </button>
                        </div>
                    </mat-dialog-actions>
                </form>

            </mat-step>

        </mat-horizontal-stepper>
    </section>
</mat-dialog-content>
