import {Component} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {Scope} from '../../constants/scopes';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ErrorService} from '../../services/error.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  domainForm = new FormControl<string | null>(localStorage.getItem('domain') ?? null, [Validators.required]);
  clientId = environment.clientId;
  scopes = [Scope.EMAIL, Scope.PROFILE, Scope.OPENID].join(' ');
  googleLoading = false;
  microsoftLoading = false;

  public constructor(public authService: AuthService, private errorService: ErrorService, public router: Router) {
    this.subscribeRefreshComponent();
  }

  googleLoginSuccess(event: any): void {
    this.saveDomain();
    this.authService.signInWithGoogle(event.detail);
  }

  microsoftAuth(): void {
    this.saveDomain();
    this.microsoftLoading = true;
    this.authService.signInWithMicrosoft();
  }

  googleAuth(): void {
    setTimeout(() => {
      this.googleLoading = true;
    });
  }

  loginError(): void {
    this.googleLoading = false;
    this.errorService.errorMessage$.next('Error: An error occurred during authentication');
  }

  saveDomain(): void {
    if (!!this.domainForm.value)
      localStorage.setItem('domain', this.domainForm.value);
  }

  isSignInDisabled(): boolean {
    return !this.domainForm.valid || this.googleLoading || this.microsoftLoading
  }

  resetPage(): void {
    this.domainForm.setValue(null);
    this.resetLoading();
  }

  resetLoading(): void {
    this.googleLoading = false;
    this.microsoftLoading = false;
  }

  subscribeRefreshComponent(): void {
    this.errorService.refresh$.subscribe((value) => {
      if (value) {
        this.resetPage();
      }
    });
  }
}
