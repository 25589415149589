import {Injectable} from '@angular/core';
import {AbstractService} from '../abstract.service';
import {Observable, Subject} from 'rxjs';
import {AODocsRole} from '../../constants/aodocs-roles-enum';
import {Module} from '../../constants/modules-enum';
import {ApiVersion} from '../../constants/apis';
import {StateModel} from '../../models/configurations/base/state.model';
import {UserService} from '../user.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends AbstractService {
  public creationSubject = new Subject();

  constructor(private userService: UserService, private http: HttpClient) {
    super();
  }

  public stateValue: StateModel[] = [
    {
      name: 'Default',
      isReadOnly: false
    },
    {
      name: 'Read Only',
      isReadOnly: true
    }
  ];

  searchLibraries(query: string, minimumRole: AODocsRole, limit: number): Observable<any> {
    let url = this.toAoDocsUrl()
      + 'library/v2beta1/search?'
      + 'facets=false'
      + '&minimumCurrentUserRole=' + minimumRole
      + '&query=' + query;

    if (limit > 0) {
      url = url.concat('&limit=' + limit);
    }
    return this.http.get<any>(url);
  }

  fetchClassList(libraryId: string): Observable<any> {
    const url = this.toAoDocsUrl() + 'documentType/v1/libraries/' + libraryId;
    return this.http.get<any>(url);
  }

  prepareDelete(id: string, module: Module): Observable<any> {
    let url;
    switch (module) {
      case Module.FOLIO:
        url = this.toUrl('folio', 'folioDelete', ApiVersion.V1);
        break;
      case Module.TEMPLATE:
        url = this.toUrl('folio', 'templateDelete', ApiVersion.V1);
        break;
      case Module.STORAGE_ACCOUNT:
        url = this.toUrl('folio', 'storageAccountsDelete', ApiVersion.V1);
        break;
      case Module.CUSTOM_ROLE:
        url = this.toUrl('folio', 'customRolesConfig', ApiVersion.V1);
        break;
    }
    url = url + '/' + id;
    return this.delete(url)
  }

  private delete(url: string): Observable<any> {
    return this.http.delete<any>(url);
  }

  fetchUserAndGroupSuggestList(query: string): Observable<any> {
    const url = this.toAoDocsUrl() + 'user/v1/userAndGroupSuggest?query=' + query;
    return this.http.get<any>(url)
  }

}
