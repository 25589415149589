<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">
  {{page + '.creator.title' |translate}}
</h2>
<mat-dialog-content id="sa-creation-dialog-content" class="dialog-padding mat-typography">
  <div class="spinner-container" *ngIf="loading$|async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$|async">
    <form [formGroup]="storageAdminsForm">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.libraryName'|translate}}
              </p>
              <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>
                  {{page + '.labels.libraryName'|translate}}
                </mat-label>
                <input #librarySearchInput
                       type="text"
                       placeholder="{{page + '.labels.selectLibrary'|translate}}"
                       matInput
                       required
                       formControlName="library"
                       (keyup)="fetchLibraries(librarySearchInput.value, 50)"
                       [matAutocomplete]="autoLibrary">
                <mat-autocomplete #autoLibrary="matAutocomplete"
                                  [displayWith]="displayByName">
                  <mat-option *ngFor="let _library of libraries" [value]="_library">
                    {{_library.name}} - {{_library.id}}
                  </mat-option>
                </mat-autocomplete>
                <button mat-button *ngIf="librarySearchInput.value"
                        matSuffix mat-icon-button aria-label="Clear"
                        (click)="this.storageAdminsForm.patchValue({library: ''})">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">
                {{page + '.labels.libraryId'|translate}}
              </p>
              <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>
                  {{page + '.labels.libraryId'|translate}}
                </mat-label>
                <input type="text"
                       matInput
                       [value]="storageAdminsForm.get('library')?.value?.id || ''"
                       required
                       readonly>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex="100">
          <p class="grey-label label-margin">
            {{page + '.labels.storageAccounts'|translate}}
          </p>
          <app-chips [searchSubject]="storageAccountSearchSubject"
                     [allItems]="filteredStorageAdminList"
                     [selectedItems]="storageAdminsSelected"
                     [selectedEventSubject]="storageAdminsSelectedSubject"
                     [clearSubject]="storageAdminsClearSubject"
                     [label]="page + '.labels.selectStorageAccounts'|translate"
                     [readonly]="false">
          </app-chips>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="100">
              <p class="grey-label label-margin">
                {{page + '.labels.gcsBucketCreation'|translate}}
              </p>
              <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>
                  {{page + '.labels.selectGcsBucket'|translate}}
                </mat-label>
                <input matInput formControlName="gcsBucket" placeholder="Value" autocomplete="off">
              </mat-form-field>

              <div *ngIf="isGcsBucketValid()" class="bucket-error-message">
                {{page + '.errorMessages.gcsBucketErrorMessage'|translate}}
              </div>

            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="100">
              <p class="grey-label label-margin">
                {{page + '.labels.azureContainerCreation'| translate}}
              </p>
              <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>
                  {{page + '.labels.selectAzureContainer'| translate}}
                </mat-label>
                <input matInput formControlName="azureContainer" placeholder="Value" autocomplete="off">
              </mat-form-field>

              <div *ngIf="isAzureContainerValid()" class="bucket-error-message">
                {{page + '.errorMessages.azureBlobStorageErrorMessage'| translate}}
              </div>

            </div>
          </div>
        </div>
      </div>

    </form>
    <mat-dialog-actions class="sa-creator-actions">
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <button appDebounceClick
                mat-button
                class="blue-label fw-500"
                type="button"
                (click)="isConfigSent = true; create()"
                [disabled]="isConfigSent || storageAdminsForm.invalid || !storageAdminsForm.get('library').value?.id">
          {{"buttons.create"|translate}}
        </button>
      </div>
    </mat-dialog-actions>
  </section>
</mat-dialog-content>
