import {Component, OnInit} from '@angular/core';
import {NavItemModel} from '../../models/nav-item-model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menu: NavItemModel [] = [
    {
      name: 'configurations',
      children: [
        {
          name: 'folio-config',
          route: '/folio-configurations'
        },
        {
          name: 'template-config',
          route: '/template-configuration'
        },
        {
          name: 'storage-account-config',
          route: '/storage-account-configuration'
        },
        {
          name: 'custom-roles-config',
          route: '/custom-roles-configuration'
        }
      ]
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
