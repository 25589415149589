import {Injectable, NgZone} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {UserService} from '../user.service';
import {AuthService} from '../auth.service';
import {catchError, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public userService: UserService, public router: Router, public ngZone: NgZone) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!localStorage['domain']) {
      this.authService.signOut();
      return false;
    }
    else {
      return this.authService.checkUser().pipe(
        map((response) => {
          if (!response.value) {
            this.authService.signOut();
          }
          return response.value;
        }), catchError((error) => {
          console.error(error);
          this.authService.signOut();
          return of(false);
        }));
    }
  }
}
