import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, Subject} from 'rxjs';
import {ConfigurationCreatorBaseComponent} from "../configuration-creator-base.component";
import {ConfigurationService} from "../../../services/configuration/configuration.service";
import {LibraryModel} from "../../../models/configurations/base/library.model";
import {FolioService} from "../../../services/configuration/folio.service";
import {FolioConfigModel, FolioLibraryConfigModel,} from "../../../models/configurations/folio-config.model";
import {StateModel} from "../../../models/configurations/base/state.model";
import {FieldsModel} from "../../../models/configurations/base/fields.model";
import {ClassModel} from "../../../models/configurations/base/class.model";
import {SuggestionsModel} from "../../../models/configurations/base/suggestions.model";
import {SimpleNameModel} from "../../../models/configurations/base/simple-name.model";
import {CustomScriptType} from "../../../constants/cs-type-enum";

@Component({
  selector: 'app-folio-config-creator',
  templateUrl: './folio-config-creator.component.html',
  styleUrls: ['../configuration-creator-base.component.scss']
})
export class FolioConfigCreatorComponent extends ConfigurationCreatorBaseComponent {
  basicInfoFormGroup: UntypedFormGroup;
  permissionsFormGroup: UntypedFormGroup;
  statesFormGroup: UntypedFormGroup;

  libraryReadOnly = false;
  isClassSelected = false;

  classList: ClassModel[];
  customScriptType: typeof CustomScriptType = CustomScriptType;

  defaultAdminsSearchSubject: Subject<string> = new Subject<string>();
  defaultAdminsList: SimpleNameModel[] = [];
  defaultAdminsPreselectedItemList: string[] = [];
  defaultAdminsSelectedListSubject: Subject<[]> = new Subject<[]>();
  defaultAdminsClearSubject: Subject<void> = new Subject<void>();

  defaultContributorsSearchSubject: Subject<string> = new Subject<string>();
  defaultContributorsList: SimpleNameModel[] = [];
  defaultContributorsPreselectedItemList: string[] = [];
  defaultContributorsSelectedListSubject: Subject<[]> = new Subject<[]>();
  defaultContributorsClearSubject: Subject<void> = new Subject<void>();

  defaultViewersSearchSubject: Subject<string> = new Subject<string>();
  defaultViewersList: SimpleNameModel[] = [];
  defaultViewersPreselectedItemList: string[] = [];
  defaultViewersSelectedListSubject: Subject<[]> = new Subject<[]>();
  defaultViewersClearSubject: Subject<void> = new Subject<void>();

  defaultDocumentManagersSearchSubject: Subject<string> = new Subject<string>();
  defaultDocumentManagersList: SimpleNameModel[] = [];
  defaultDocumentManagersPreselectedItemList: string[] = [];
  defaultDocumentManagersSelectedListSubject: Subject<[]> = new Subject<[]>();
  defaultDocumentManagersClearSubject: Subject<void> = new Subject<void>();

  defaultCategoryValueManagersSearchSubject: Subject<string> = new Subject<string>();
  defaultCategoryValueManagersList: SimpleNameModel[] = [];
  defaultCategoryValueManagersPreselectedItemList: string[] = [];
  defaultCategoryValueManagersSelectedListSubject: Subject<[]> = new Subject<[]>();
  defaultCategoryValueManagersClearSubject: Subject<void> = new Subject<void>();

  defaultMaintenanceManagersSearchSubject: Subject<string> = new Subject<string>();
  defaultMaintenanceManagersList: SimpleNameModel[] = [];
  defaultMaintenanceManagersPreselectedItemList: string[] = [];
  defaultMaintenanceManagersSelectedListSubject: Subject<[]> = new Subject<[]>();
  defaultMaintenanceManagersClearSubject: Subject<void> = new Subject<void>();

  administratorsFieldsList: FieldsModel[] = [];
  contributorFieldsList: FieldsModel[] = [];
  viewersFieldsList: FieldsModel[] = [];
  documentManagersFieldsList: FieldsModel[] = [];
  categoryValueManagersFieldsList: FieldsModel[] = [];
  maintenanceManagersFieldsList: FieldsModel[] = [];

  isConfigSent: boolean = false;

  constructor(public configurationService: ConfigurationService,
              protected folioService: FolioService,
              dialogRef: MatDialogRef<FolioConfigCreatorComponent>,
              formBuilder: UntypedFormBuilder,
              cdr: ChangeDetectorRef,
              dialogService: DialogService,
              translate: TranslateService,
              ngZone: NgZone) {
    super(dialogRef,
      formBuilder,
      cdr,
      dialogService,
      translate,
      ngZone,
      configurationService);

    super.page = 'folio-config';
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.initForm();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.initSearchSubject();
  }

  private initSearchSubject() {
    this.defaultAdminsSearchSubject
      .subscribe(async (query) => {
        if (query.length > 0) {
          try {
            await this.ngZone.run(async () => {
              const result =  await firstValueFrom(this.configurationService.fetchUserAndGroupSuggestList(query));
              const resp: SuggestionsModel[] = result && result?.suggestions
                ? result.suggestions
                : [];
              this.defaultAdminsList = resp.map(({value: name}) => ({name}));
            });
          } catch (error) {
            this.ngZone.run(() => {
              this.dialogService.error(error.result.error.message)
            });
          }
        } else {
          this.defaultAdminsList = [];
        }
      });

    this.defaultContributorsSearchSubject
      .subscribe(async (query) => {
        if (query.length > 0) {
          try {
            await this.ngZone.run(async () => {
              const result = await firstValueFrom(this.configurationService.fetchUserAndGroupSuggestList(query));
              const resp: SuggestionsModel[] = result && result?.suggestions
                ? result.suggestions
                : [];
              this.defaultContributorsList = resp.map(({value: name}) => ({name: name}));
            });
          } catch (error) {
            this.ngZone.run(() => {
              this.dialogService.error(error.result.error.message)
            });
          }
        } else {
          this.defaultContributorsList = [];
        }
      });

    this.defaultViewersSearchSubject
      .subscribe(async (query) => {
        if (query.length > 0) {
          try {
            await this.ngZone.run(async () => {
              const result =  await firstValueFrom(this.configurationService.fetchUserAndGroupSuggestList(query));
              const resp: SuggestionsModel[] = result && result?.suggestions
                ? result.suggestions
                : [];
              this.defaultViewersList = resp.map(({value: name}) => ({name: name}));
            });
          } catch (error) {
            this.ngZone.run(() => {
              this.dialogService.error(error.result.error.message)
            });
          }
        } else {
          this.defaultViewersList = [];
        }
      });

    this.defaultDocumentManagersSearchSubject
      .subscribe(async (query) => {
        if (query.length > 0) {
          try {
            await this.ngZone.run(async () => {
              const result =  await firstValueFrom(this.configurationService.fetchUserAndGroupSuggestList(query));
              const resp: SuggestionsModel[] = result && result?.suggestions
                ? result.suggestions
                : [];
              this.defaultDocumentManagersList = resp.map(({value: name}) => ({name: name}));
            });
          } catch (error) {
            this.ngZone.run(() => {
              this.dialogService.error(error.result.error.message)
            });
          }
        } else {
          this.defaultDocumentManagersList = [];
        }
      });

    this.defaultCategoryValueManagersSearchSubject
      .subscribe(async (query) => {
        if (query.length > 0) {
          try {
            await this.ngZone.run(async () => {
              const result =  await firstValueFrom(this.configurationService.fetchUserAndGroupSuggestList(query));
              const resp: SuggestionsModel[] = result && result?.suggestions
                ? result.suggestions
                : [];
              this.defaultCategoryValueManagersList = resp.map(({value: name}) => ({name: name}));
            });
          } catch (error) {
            this.ngZone.run(() => {
              this.dialogService.error(error.result.error.message)
            });
          }
        } else {
          this.defaultCategoryValueManagersList = [];
        }
      });

    this.defaultMaintenanceManagersSearchSubject
      .subscribe(async (query) => {
        if (query.length > 0) {
          try {
            await this.ngZone.run(async () => {
              const result =  await firstValueFrom(this.configurationService.fetchUserAndGroupSuggestList(query));
              const resp: SuggestionsModel[] = result && result?.suggestions
                ? result.suggestions
                : [];
              this.defaultMaintenanceManagersList = resp.map(({value: name}) => ({name: name}));
            });
          } catch (error) {
            this.ngZone.run(() => {
              this.dialogService.error(error.result.error.message)
            });
          }
        } else {
          this.defaultMaintenanceManagersList = [];
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.defaultAdminsSearchSubject.unsubscribe();
    this.defaultAdminsSelectedListSubject.unsubscribe();
    this.defaultAdminsClearSubject.unsubscribe();

    this.defaultContributorsSearchSubject.unsubscribe();
    this.defaultContributorsSelectedListSubject.unsubscribe();
    this.defaultContributorsClearSubject.unsubscribe();

    this.defaultViewersSearchSubject.unsubscribe();
    this.defaultViewersSelectedListSubject.unsubscribe();
    this.defaultViewersClearSubject.unsubscribe();

    this.defaultDocumentManagersSearchSubject.unsubscribe();
    this.defaultDocumentManagersSelectedListSubject.unsubscribe();
    this.defaultDocumentManagersClearSubject.unsubscribe();

    this.defaultCategoryValueManagersSearchSubject.unsubscribe();
    this.defaultCategoryValueManagersSelectedListSubject.unsubscribe();
    this.defaultCategoryValueManagersClearSubject.unsubscribe();

    this.defaultMaintenanceManagersSearchSubject.unsubscribe();
    this.defaultMaintenanceManagersSelectedListSubject.unsubscribe();
    this.defaultMaintenanceManagersClearSubject.unsubscribe();
  }

  private initForm(): void {
    this.initBasicInfoFormGroup();
    this.initPermissionsFormGroup();
    this.initStatesFormGroup();

    this.initSubscriptions();
  }

  isCustomScriptTypeUpdateSelected(): boolean {
    return this.basicInfoFormGroup.get('customScriptType').value as unknown as CustomScriptType == CustomScriptType.UPDATE;
  }

  private initSubscriptions() {
    this.defaultAdminsSelectedListSubject
      .subscribe((item) => {
        this.permissionsFormGroup
          .patchValue({
            defaultAdmins: item
          });
      });

    this.defaultContributorsSelectedListSubject
      .subscribe((item) => {
        this.permissionsFormGroup
          .patchValue({
            defaultContributors: item
          });
      });

    this.defaultViewersSelectedListSubject
      .subscribe((item) => {
        this.permissionsFormGroup
          .patchValue({
            defaultViewers: item
          });
      });

    this.defaultDocumentManagersSelectedListSubject
      .subscribe((item) => {
        this.permissionsFormGroup
          .patchValue({
            defaultDocumentManagers: item
          });
      });

    this.defaultCategoryValueManagersSelectedListSubject
      .subscribe((item) => {
        this.permissionsFormGroup
          .patchValue({
            defaultCategoryValueManagers: item
          });
      });

    this.defaultMaintenanceManagersSelectedListSubject
      .subscribe((item) => {
        this.permissionsFormGroup
          .patchValue({
            defaultMaintenanceManagers: item
          });
      });
  }

  private initBasicInfoFormGroup(): void {
    this.resetBasicInfoFormGroup();
  }

  private resetBasicInfoFormGroup() {
    this.basicInfoFormGroup = this.formBuilder
      .group({
        library: [
          '', Validators.required
        ],
        class: [
          '', Validators.required
        ],
        customScriptType: [
          CustomScriptType.UPDATE, Validators.required
        ]
      });
  }

  private initPermissionsFormGroup(): void {
    this.resetPermissionsFormGroup();
  }

  private resetPermissionsFormGroup(): void {
    this.permissionsFormGroup = this.formBuilder
      .group({
        defaultAdmins: [
          []
        ],
        defaultContributors: [
          []
        ],
        defaultViewers: [
          []
        ],
        defaultDocumentManagers: [
          []
        ],
        defaultCategoryValueManagers: [
          []
        ],
        defaultMaintenanceManagers: [
          []
        ],
        administratorFields: [
          []
        ],
        contributorFields: [
          [], Validators.required
        ],
        viewersFields: [
          []
        ],
        documentManagersFields: [
          []
        ],
        categoryValueManagersFields: [
          []
        ],
        maintenanceManagersFields: [
          []
        ],
      });
  }

  private initStatesFormGroup(): void {
    this.resetStatesFormGroup();
  }

  private resetStatesFormGroup(): void {
    this.statesFormGroup = this.formBuilder.group({
      states: this.formBuilder.array([]),
    });
  }

  get formLibrary(): LibraryModel {
    return this.basicInfoFormGroup.get('library')?.value as LibraryModel;
  }

  get formStates(): UntypedFormArray {
    return this.statesFormGroup.get('states') as UntypedFormArray;
  }

  clearForm() {
    super.libraries = []
    this.classList = [];
    this.isClassSelected = false;

    this.resetBasicInfoFormGroup();
    this.resetPermissionsFormGroup();
    this.resetStatesFormGroup();
  }

  checkLibraryState(event: any): void {
    const key = event.keyCode;
    this.libraryReadOnly = key !== 8
      && key !== 46
      && !!this.formLibrary?.name;
  }

  loadLibraries(query: string): void {
    this.classList = [];
    this.isClassSelected = false;

    this.basicInfoFormGroup
      .patchValue({
        class: ''
      });

    if (!this.libraryReadOnly) {
      query = query.trim();
      if (query.length > 2) {
        super.fetchLibraries(query, 0)
      }
    }
  }

  async onSelectionLibrary(library: LibraryModel): Promise<void> {
    if (library && library?.id) {
      this.basicInfoFormGroup
        .patchValue({
          library: library
        });

      this.resetPermissionsFormGroup()
      this.resetStatesFormGroup();

      try {
        let res = await firstValueFrom(this.configurationService.fetchClassList(library.id));
        this.classList = res.items;
      } catch (error) {
        console.error(error);
      }
    }
  }

  async onSelectionClass(selectedClass: ClassModel) {
    if (selectedClass && selectedClass?.id) {
      const libraryId = this.formLibrary?.id;
      const classId = selectedClass.id;
      if (libraryId && classId) {
        try {
          const fieldsResult = await firstValueFrom(this.folioService.fetchFieldsList(libraryId, classId));
          const fields: FieldsModel[] = fieldsResult && fieldsResult?.fields && fieldsResult.fields.length > 0
            ? fieldsResult.fields.filter(value => value.type === 'PERSON')
            : [];
          this.administratorsFieldsList = fields;
          this.contributorFieldsList = fields;
          this.viewersFieldsList = fields;
          this.documentManagersFieldsList = fields;
          this.categoryValueManagersFieldsList = fields;
          this.maintenanceManagersFieldsList = fields;

          this.formStates.clear();
          const statesResult = await firstValueFrom(this.folioService.fetchStateList(libraryId, classId));
          const stateList: StateModel[] = statesResult && statesResult?.workflows && statesResult.workflows.length > 0
            ? statesResult.workflows[0]?.states
            : [];
          if (stateList.length > 0) {
            let transitionPosition = 1;
            stateList.forEach(state => {
              const s: StateModel = {
                name: state.name,
                isReadOnly: false,
                position: transitionPosition
              }
              this.formStates.push(this.formBuilder.group(s));
              transitionPosition++;
            });
          }

          this.isClassSelected = true;
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  create(): void {
    this.loadingSubject.next(true);
    const basicInfo = this.basicInfoFormGroup.value;
    const permission = this.permissionsFormGroup.value;
    const states = this.statesFormGroup.value;

    const folioLibraryConfigModel: FolioLibraryConfigModel = {
      folioLibraryId: basicInfo.library.id,
      folioLibraryName: basicInfo.library.name,
      folioClassName: basicInfo.class.name,
      folioClassId: basicInfo.class.id,

      customScriptType: basicInfo.customScriptType,

      administratorFields: permission.administratorFields.map(fields => fields.displayName),
      contributorFields: permission.contributorFields.map(fields => fields.displayName),
      viewersFields: permission.viewersFields?.map(fields => fields.displayName),
      documentManagersFields: permission.documentManagersFields?.map(fields => fields.displayName),
      categoryValueManagersFields: permission.categoryValueManagersFields?.map(fields => fields.displayName),
      maintenanceManagersFields: permission.maintenanceManagersFields?.map(fields => fields.displayName),

      defaultContributors: permission.defaultContributors
        .map(value => value.name)
        .join(','),

      defaultAdmins: permission.defaultAdmins
        .map(value => value.name)
        .join(','),

      defaultViewers: permission.defaultViewers
        .map(value => value.name)
        .join(','),

      defaultDocumentManagers: permission.defaultDocumentManagers
        .map(value => value.name)
        .join(','),

      defaultCategoryValueManagers: permission.defaultCategoryValueManagers
        .map(value => value.name)
        .join(','),

      defaultMaintenanceManagers: permission.defaultMaintenanceManagers
        .map(value => value.name)
        .join(',')
    };

    let folioConfigModel: FolioConfigModel;
    if (basicInfo.customScriptType != CustomScriptType.WORKFLOW) {
      folioConfigModel = {
        folioLibrary: folioLibraryConfigModel,
        stateList: states.states
      }
    } else {
      folioConfigModel = {
        folioLibrary: folioLibraryConfigModel,
        stateList: []
      }
    }

    console.dir(folioConfigModel);

    this.folioService.create(folioConfigModel).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.dialogRef.close();
        this.configurationService.creationSubject.next(true);
        console.log(resp);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogRef.close();
        if (error) {
          console.dir(error);
          this.dialogService.error(
            error.error.error.message ?? error.error.message,
            `Error ${error.error.error.code} - ${error.error.error?.errors[0].reason}`
          )
        }
      }
    });
  }
}
