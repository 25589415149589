<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">
  {{page + '.details.title'|translate}}
</h2>
<mat-dialog-content id="details-dialog-content" class="dialog-padding mat-typography">
  <section>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.folioLibrary'|translate}}
        </p>
        <p>
          {{customRolesConfig.folioName}}
        </p>
      </div>
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.folioLibraryId'|translate}}
        </p>
        <p>
          {{customRolesConfig.folioId}}
        </p>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.templateLibrary'|translate}}
        </p>
        <p>
          {{customRolesConfig.templateName}}
        </p>
      </div>
      <div fxLayout="column" fxFlex="50">
        <p class="grey-label label-small-margin">
          {{page + '.labels.templateId'|translate}}
        </p>
        <p>
          {{customRolesConfig.templateId}}
        </p>
      </div>
    </div>
  </section>
  <br/>
  <mat-divider></mat-divider>
  <br/>
  <section class="custom-roles-mapping">
    <ng-container *ngFor="let customRoles of splitCustomRoles">
      <div fxLayout="row" fxLayoutAlign="space-between">

        <ng-container *ngFor="let mapping of customRoles">
          <div fxLayout="column" fxFlex="50" class="list-container">
            <p class="grey-label label-small-margin">
              {{mapping.role.name}}
            </p>
            <ul>
              <li *ngFor="let field of mapping.fields">
                {{field.name}}
              </li>
            </ul>
          </div>
        </ng-container>
      </div>

    </ng-container>

  </section>

</mat-dialog-content>
