import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './services/guard/auth-guard.service';
import {SignInComponent} from './pages/sign-in/sign-in.component';
import {TechnicalSignInComponent} from './pages/sign-in/technical-sign-in/technical-sign-in.component';
import {SignOutGuard} from './services/guard/signout-guard.service';

const routes: Routes = [
  {
    path: 'configurations',
    loadChildren: () => import('./modules/configuration-list.module').then(m => m.ConfigurationListModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [SignOutGuard]
  },
  {
    path: 'technical-sign-in',
    component: TechnicalSignInComponent,
    canActivate: [SignOutGuard]
  },
  {
    path: '',
    redirectTo: 'configurations',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
