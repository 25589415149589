import {Component, OnInit} from '@angular/core';
import {AboutDialogComponent} from '../about-dialog/about-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openAboutDialog(): void {
    this.dialog.open(AboutDialogComponent, {autoFocus: false});
  }

}
