<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">
  {{page + '.details.title'|translate}}
</h2>
<mat-dialog-content id="details-dialog-content" class="dialog-padding mat-typography">
  <section>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="100">
        <p class="grey-label label-margin">
          {{page + '.labels.templateName'|translate}}
        </p>
        <p>
          {{template?.templateName}}
        </p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="48">
        <p class="grey-label label-margin">
          {{page + '.labels.libraryName'|translate}}
        </p>
        <p>
          {{library?.name}}
        </p>
      </div>
      <div fxLayout="column" fxFlex="48">
        <p class="grey-label label-margin">
          {{page + '.labels.libraryId'|translate}}
        </p>
        <p>
          {{library?.id}}
        </p>
      </div>
    </div>
  </section>
</mat-dialog-content>

