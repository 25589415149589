import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import {EMPTY, first, Observable, switchMap} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {map} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  readonly tokenLife: number = 3540; // 59 minutes

  constructor(private auth: AngularFireAuth, private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const now = Math.floor(Date.now() / 1000);
    const tokenCreationTime = parseInt(localStorage['signedInAt']);
    if (now - tokenCreationTime > this.tokenLife) {
      this.authService.signOut();
      return EMPTY;
    }
    req = req.clone({
      setParams: {domain: localStorage['domain']},
    });
    if (localStorage['gsi-token']) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${localStorage['gsi-token']}`
        },
      });
      return next.handle(req);
    }
    /*
    Id token refers the Observable of the rxjs library inside Angular fire and not the rxjs global library
    */
    return (this.auth.idToken as unknown as Observable<string | null>).pipe(
      first(),
      map((token) => (token ? req.clone({setHeaders: {Authorization: `Bearer ${token}`}}) : req)),
      switchMap((authReq: HttpRequest<any>) => next.handle(authReq))
    );
  }
}
