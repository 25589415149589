<section>
  <img [matMenuTriggerFor]="appMenu" [src]="getProfilePicture()" alt="profile" class="mat-fab profile">
  <mat-menu #appMenu>
    <section class="profile-details" fxLayout="column">
      <img [src]="getProfilePicture()" alt="profile">
      <p>{{getProfileName()}}</p>
      <p class="email">{{getProfileEmail()}}</p>
    </section>
    <mat-divider></mat-divider>
    <a (click)="signOut()" mat-menu-item>Sign-out</a>
  </mat-menu>
</section>
