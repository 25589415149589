import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {ConfigurationDetailsBaseComponent} from '../configuration-details-base.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StorageAccountConfigModel} from "../../../models/configurations/storage-account-config.model";

@Component({
  selector: 'app-storage-account-config',
  templateUrl: './storage-account-config-details.component.html',
  styleUrls: ['../configuration-details-base.component.scss']
})
export class StorageAccountConfigDetailsComponent extends ConfigurationDetailsBaseComponent {

  storageAccountConfig: StorageAccountConfigModel;

  constructor(@Inject(MAT_DIALOG_DATA)  data: any,
              dialogRef: MatDialogRef<StorageAccountConfigDetailsComponent>,
              protected cdr: ChangeDetectorRef) {
    super(data, dialogRef, cdr);

    super.page = 'storage-account-config';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.storageAccountConfig = this.data;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }

}
