import {AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export const storageAccountConfigCreatorValidators: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const storageAccounts = control.get('storageAccounts');
    const gcsBucket = control.get('gcsBucket');
    const azureContainer = control.get('azureContainer');

    if (storageAccounts.value.length !== 0 || gcsBucket.value || azureContainer.value) {
        return null;
    }
    return {isNotValid: true};
};

export function nameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const forbidden = nameRe.test(control.value);
        return forbidden ? null : {forbiddenName: control.value};
    };
};

export function duplicateValidator(): ValidatorFn {
    return (formArray: FormArray) => {
        const totalSelected = formArray.controls.map(control => control.get('customRole').value.id);
        const hasDuplicate = totalSelected.some(
            (name, index) => totalSelected.indexOf(name, index + 1) !== -1
        );
        return hasDuplicate ? {duplicate: true} : null;
    };
}

export function BothOrNoneValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        const control1 = group.controls.customRole;
        const control2 = group.controls.fieldPerson;

        if (control1.value && (control2.value || control2.value.length > 0)) {
            return ;
        }
        if (!control1.value && (!control2.value || control2.value.length == 0)) {
            return ;
        }
        return {bothOrNone: true};
    };
}
