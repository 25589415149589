import {Injectable} from '@angular/core';
import {AbstractService} from '../abstract.service';
import {ApiVersion} from '../../constants/apis';
import {TemplateConfigModel} from '../../models/configurations/template-config.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  create(templateConfig: TemplateConfigModel): Observable<any> {
    const url = this.toUrl('folio', 'templateCreate', ApiVersion.V1);
    return this.http.post<any>(url, templateConfig.template);
  }

  list(): Observable<any> {
    const url = this.toUrl('folio', 'templateList', ApiVersion.V1);
    return this.http.get<any>(url);
  }
}
