<section class="main-content">
    <section class="login-section">
      <section>
        <form>
          <div class="form-group">
            <input
              [formControl]="domainForm"
              type="text"
              id="domain"
              aria-describedby="domainHelp"
              placeholder="Enter Domain"
              required
            />
          </div>
        </form>
      </section>
      <div class="mat-elevation-z2 sign-in-button">
        <div
          class="button-header"
          (click)="isSignInDisabled() ? null : microsoftAuth()"
          [class.disabled]="isSignInDisabled()"
        >
          <mat-progress-spinner *ngIf="microsoftLoading" mode="indeterminate" [diameter]="24"></mat-progress-spinner>
          <img alt="logo" class="logo-size" src="../../../assets/img/microsoft_icon.svg"/>
          <span>Sign in with Microsoft</span>
        </div>
      </div>
      Or
      <div class="mat-elevation-z2 sign-in-button">
        <ao-google-login
          [clientId]="clientId"
          [scopes]="scopes"
          (loginSuccess)="googleLoginSuccess($event)"
          (loginError)="loginError()"
          [disabled]="isSignInDisabled()"
          display-mode="content"
          authentication-mode="token"
          id="gLogin"
        >
          <div
            class="button-header"
            (click)="isSignInDisabled() ? null : googleAuth()"
            [class.disabled]="isSignInDisabled()"
          >
            <mat-progress-spinner *ngIf="googleLoading" mode="indeterminate" [diameter]="24"></mat-progress-spinner>
            <img alt="logo" class="logo-size" src="../../../assets/img/google_icon.svg"/>
            <span>Sign in with Google</span>
          </div>
        </ao-google-login>
      </div>
    </section>
</section>
