import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {ConfigurationCreatorBaseComponent} from '../configuration-creator-base.component';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {TemplateConfigModel, TemplateModel} from '../../../models/configurations/template-config.model';
import {TemplateService} from '../../../services/configuration/template.service';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-template-config-creator',
  templateUrl: './template-config-creator.component.html',
  styleUrls: ['../configuration-creator-base.component.scss']
})
export class TemplateConfigCreatorComponent extends ConfigurationCreatorBaseComponent {

  templateForm: UntypedFormGroup;

  isConfigSent: boolean = false;

  constructor(dialogRef: MatDialogRef<TemplateConfigCreatorComponent>,
              protected templateService: TemplateService,
              protected formBuilder: UntypedFormBuilder,
              protected configurationService: ConfigurationService,
              protected cdr: ChangeDetectorRef,
              protected dialogService: DialogService,
              protected translate: TranslateService,
              protected ngZone: NgZone) {
    super(
      dialogRef,
      formBuilder,
      cdr,
      dialogService,
      translate,
      ngZone,
      configurationService);

    super.page = 'template-config';
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.initForm();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit()
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }

  initForm(): void {
    this.templateForm = this.formBuilder
      .group({
        templateName: [
          '', Validators.required
        ],
        library: [
          '', Validators.required
        ]
      });

    this.templateForm.get('library').valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged())
      .subscribe((value: string) => {
        if (value && typeof value === 'string' && value.trim().length >= 3) {
          super.fetchLibraries(value, 50)
        } else {
          this.libraries = [];
        }
      })
  }

  create(): void {
    this.loadingSubject.next(true);
    const data: TemplateModel = this.templateForm.value;
    const template: TemplateConfigModel = {
      template: {
        templateName: data.templateName,
        library: {
          id: data.library.id,
          name: data.library.name,
        }
      }
    };
    this.templateService.create(template).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.dialogRef.close();
        console.log(resp);
        this.configurationService.creationSubject.next(true);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogRef.close();
        if (error) {
          console.dir(error);
          this.dialogService.error(
            error.error.error.message ?? error.error.message,
            `Error ${error.error.error.code} - ${error.error.error?.errors[0].reason}`
          )
        }
      }
    });
  }


}
