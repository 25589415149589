import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {ConfigurationListBaseComponent} from '../configuration-list-base.component';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {UserService} from '../../../services/user.service';
import {DialogService} from '../../../services/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {StorageAccountConfigModel} from '../../../models/configurations/storage-account-config.model';
import {StorageAccountConfigCreatorComponent} from '../../create-configuration/storage-account-config-creator/storage-account-config-creator.component';
import {StorageAccountConfigDetailsComponent} from '../../details-configuration/storage-account-config-details/storage-account-config-details.component';
import {StorageAccountService} from '../../../services/configuration/storage-account.service';
import {Module} from '../../../constants/modules-enum';

@Component({
  selector: 'app-storage-account-config',
  templateUrl: './storage-account-config-list.component.html',
  styleUrls: ['../configuration-list-base.component.scss']
})
export class StorageAccountConfigListComponent extends ConfigurationListBaseComponent {

  constructor(protected storageAccountService: StorageAccountService,
              configurationService: ConfigurationService,
              ngZone: NgZone,
              userService: UserService,
              dialogService: DialogService,
              dialog: MatDialog,
              cdr: ChangeDetectorRef,
              translate: TranslateService) {
    super(
      configurationService,
      ngZone,
      userService,
      dialogService,
      dialog,
      cdr,
      translate);

    super.page = 'storage-account-config';

    super.columns = [
      {
        name: 'storageLibraryName',
        show: true
      },
      {
        name: 'storageLibraryId',
        show: true
      },
      {
        name: 'storageAccounts',
        show: true
      },
      {
        name: 'storageGcsBucket',
        show: true
      },
      {
        name: 'storageAzureContainer',
        show: true
      },
      {
        name: 'view',
        show: true
      },
      {
        name: 'delete',
        show: true
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSubscriptions();
    this.initFilterPredicate();
    this.refresh();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  openCreateDialog(): void {
    super.openDialog(StorageAccountConfigCreatorComponent, {
      width: '680px',
    });
  }

  openDetailsDialog(element): void {
    super.openDialogWithElement(StorageAccountConfigDetailsComponent, element, {
      width: '680px',
      height: '445px',
      data: element,
    });
  }

  openConfirmStorageAccountDeleteDialog(storageAccountConfigModel: StorageAccountConfigModel) {
    super.openConfirmDeleteDialog(
      storageAccountConfigModel.folioLibraryName,
      storageAccountConfigModel.folioLibraryId,
      'dialog.deleteStorageAccountConfiguration',
      Module.STORAGE_ACCOUNT
    );
  }

  refresh(): void {
    this.loadValues();
  }

  private initSubscriptions(): void {
    this.deletionSubscription = this.deleteSubject
      .subscribe(() => this.refresh());

    this.creationSubscription = this.configurationService.creationSubject
      .subscribe(() => this.refresh());
  }

  private initFilterPredicate() {
    this.dataSource.filterPredicate =
      (data: StorageAccountConfigModel, filter: string): boolean =>
        data.folioLibraryId?.trim().toLowerCase().includes(filter) ||
        data.folioLibraryName?.trim().toLowerCase().includes(filter) ||
        data.storageAccounts?.some(account => account?.trim().toLowerCase().includes(filter));
  }

  private loadValues(): void {
    this.loadingSubject.next(true);
    this.storageAccountService.list().subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.dataSource.data = resp.items;
        this.noDataMessage = this.dataSource.data.length > 0
          ? ''
          : this.translate.instant('storage-account-config.list.noDataYet');
      },
      error: (error) => {
        this.loadingSubject.next(false);
        if (error) {
          console.dir(error);
          this.dialogService.error(
            error?.errors[0]?.message ?? error.message,
            `Error ${error?.code} - ${error?.errors[0]?.reason}`
          )
        }
      }
    });
  }

  truncateCellContent(cellContent: string, maxLength: number): string {
    if (!cellContent) {
      return cellContent;
    }
    return cellContent.length > maxLength ? cellContent.substring(0, maxLength) + "..." : cellContent;
  }

}
