import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserService} from './services/user.service';
import {User} from './models/user.model';
import {I18nService} from './services/i18n.service';
import {ErrorService} from './services/error.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  aoDocsUserSubscription: Subscription;
  aoDocsUser: User;

  constructor(public userService: UserService,
              private errorService: ErrorService,
              private _snackBar: MatSnackBar,
              private cdr: ChangeDetectorRef,
              public i18nService: I18nService) {
    this.i18nService.initTranslateService();
    this.initAODocsUserListener();
    this.errorService.errorMessage$.subscribe((message) => this.openSnackBar(message));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.aoDocsUserSubscription.unsubscribe();
  }

  initAODocsUserListener(): void {
    this.aoDocsUserSubscription = this.userService.userSubject.subscribe((AODocsUser) => {
      this.setAODocsUser(AODocsUser);
    });
  }

  setAODocsUser(AODocsUser: User): void {
    this.aoDocsUser = AODocsUser;
    localStorage.setItem('user', JSON.stringify(AODocsUser));
    this.cdr.detectChanges();
  }

  getAODocsUser(): User {
    if (!!localStorage['user']) {
      this.aoDocsUser = JSON.parse(localStorage['user']);
    }
    return this.aoDocsUser
  }

  isSignedIn(): boolean {
    return !!this.getAODocsUser();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Ok', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['alert-error'],
    });
  }
}
