<section *ngIf="domain" fxHide.xs fxLayout="row">
  <mat-icon class="domain-header-logo">business</mat-icon>
  <div class="domain-header">{{domain}}</div>
</section>
<section *ngIf="domain" fxHide fxShow.xs>
  <mat-icon [matMenuTriggerFor]="appMenu" class="domain-header-logo">business</mat-icon>
  <mat-menu #appMenu>
    <p class="domain-header-xs">{{domain}}</p>
  </mat-menu>
</section>
