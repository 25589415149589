<section>
  <button [matMenuTriggerFor]="appMenu" class="help" mat-icon-button matTooltip="Help">
    <mat-icon>help</mat-icon>
  </button>
  <mat-menu #appMenu>
    <a href="https://support.aodocs.com/hc/en-us/articles/9942272517147-What-is-Folio-" mat-menu-item
       target="_blank">Knowledge
      base</a>
    <mat-divider></mat-divider>
    <a href="https://aodocs.zendesk.com/" mat-menu-item target="_blank">Support</a>
    <mat-divider></mat-divider>
    <a href="https://status.aodocs.com/" mat-menu-item target="_blank">Status page</a>
    <mat-divider></mat-divider>
    <a href="https://www.aodocs.com/privacy-policy/" mat-menu-item target="_blank">Privacy policy</a>
    <mat-divider></mat-divider>
    <a (click)="openAboutDialog()" mat-menu-item target="_blank">About</a>
  </mat-menu>
</section>
