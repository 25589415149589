import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ErrorService} from '../../../services/error.service';
import {environment} from "../../../../environments/environment";
import {Scope} from "../../../constants/scopes";

@Component({
  selector: 'app-technical-sign-in',
  templateUrl: './technical-sign-in.component.html',
  styleUrls: ['./../sign-in.component.scss']
})
export class TechnicalSignInComponent implements OnInit {
  form = this.fb.group({
    domain: ['', Validators.required],
    secret: ['', Validators.required]
  });

  clientId = environment.clientId;
  scopes = [Scope.EMAIL, Scope.PROFILE, Scope.OPENID].join(' ');
  googleLoading = false;
  microsoftLoading = false;

  done = false;

  constructor(private authService: AuthService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private errorService: ErrorService) {
    this.subscribeRefreshComponent();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.done = !!params.done);
  }

  microsoftAuth(): void {
    this.microsoftLoading = true;
    this.saveDomain();
    this.authService.techSignInWithMicrosoft(this.secretKey);
  }

  saveDomain(): void {
    if (!!this.domain)
      localStorage.setItem('domain', this.domain);
  }

  get domain() {
    return this.form.get('domain').value;
  }

  get secretKey() {
    return this.form.get('secret').value;
  }

  set domain(value: string) {
    this.form.get('domain').setValue(value);
  }

  set secretKey(value: string) {
    this.form.get('secret').setValue(value);
  }

  resetPage(): void {
    this.domain = null;
    this.secretKey = null;
    this.resetLoading();
  }

  resetLoading(): void {
    this.microsoftLoading = false;
    this.googleLoading = false;
  }

  subscribeRefreshComponent(): void {
    this.errorService.refresh$.subscribe((value) => {
      if (value) {
        this.resetPage();
      }
    });
  }

  googleLoginSuccess(event: any): void {
    this.saveDomain();
    this.authService.techSignInWithGoogle(event.detail.code, this.domain, this.secretKey);
  }

  googleAuth(): void {
    setTimeout(() => {
      this.googleLoading = true;
    });
  }

  loginError(): void {
    this.googleLoading = false;
    this.errorService.errorMessage$.next('Error: An error occurred during authentication');
  }

  isSignInDisabled(): boolean {
    return !this.form.valid || this.googleLoading || this.microsoftLoading
  }
}
