import {AbstractService} from "../abstract.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiVersion} from "../../constants/apis";
import {CustomRole} from "../../models/configurations/library-custom-role.model";

@Injectable({
    providedIn: 'root'
})
export class CustomRoleService extends AbstractService {

    constructor(private http: HttpClient) {
        super();
    }

    getByLibraryId(libraryId: string): Observable<any> {
        const url = this.toUrl('folio', 'customRoles/' + libraryId, ApiVersion.V1);
        return this.http.get<any>(url);
    }

    list(): Observable<{items : CustomRole[]}> {
        const url = this.toUrl('folio', 'customRolesConfig', ApiVersion.V1);
        return this.http.get<{items : CustomRole[]}>(url);
    }

    getById(id: string): Observable<CustomRole> {
        const url = this.toUrl('folio', 'customRolesConfig/' + id, ApiVersion.V1);
        return this.http.get<any>(url);
    }

    save(customRole: CustomRole): Observable<any> {
        const url = this.toUrl('folio', 'customRolesConfig', ApiVersion.V1);
        return this.http.post<any>(url, customRole);
    }


}
