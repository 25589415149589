import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
  selectable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formCtrl = new UntypedFormControl();
  filtered: Observable<any[]>;
  selectedItems$: any[];
  existingItems$: any[];
  newlyAddedItems$: any[] = [];
  allItems$: any[];
  readonly$: boolean;
  required$: boolean;
  maxItems$: number;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() selectedEventSubject: Subject<any[]>;
  @Input() clearSubject: Subject<void>;

  @Input() searchSubject: Subject<string> = new Subject<string>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
  }

  @Input() set allItems(value: any[]) {
    this.allItems$ = value;
  }

  get allItems(): any[] {
    return this.allItems$;
  }

  @Input() set selectedItems(value: any[]) {
    this.selectedItems$ = [...value];
    this.existingItems$ = [...this.selectedItems$];
  }

  get selectedItems(): any[] {
    return this.selectedItems$;
  }

  set newlyAddedItems(value: any[]) {
    this.newlyAddedItems$ = [...value];
  }

  get newlyAddedItems(): any[] {
    return this.newlyAddedItems$;
  }

  @Input() set readonly(value: boolean) {
    this.readonly$ = value;
  }

  get readonly(): boolean {
    return this.readonly$;
  }

  @Input() set required(value: boolean) {
    this.required$ = value;
  }

  get required(): boolean {
    return this.required$;
  }

  @Input() set maxItems(value: number) {
    this.maxItems$ = value;
  }

  get maxItems(): number {
    return this.maxItems$;
  }

  ngOnInit(): void {
    this.clearSubject.subscribe(() => this.selectedItems$ = []);
    this.formCtrl.valueChanges.subscribe((item) => {
      if (typeof item === 'string'){
        if (item && item.trim().length >= 3){
          this.searchSubject.next(item);
        } else {
          this.searchSubject.next('');
        }
      }
    })
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput;
    const value = event.value;

    if ((value || '')
      && (this.allItems$.filter(item => item.name === value).length > 0)
      && (this.selectedItems$.filter(item => item.name === value).length === 0)) {
      const fullValue = this.allItems$.filter(item => item.name === value)[0];
      this.selectedItems$.push(fullValue);
      this.newlyAddedItems$.push(fullValue);
      this.selectedEventSubject.next(this.newlyAddedItems$);
    }
    if (input) {
      event.value = '';
    }

    this.formCtrl.setValue(null);
  }

  remove(item: string): void {
    const index = this.selectedItems$.indexOf(item);
    if (index >= 0) {
      this.selectedItems$.splice(index, 1);
      this.newlyAddedItems$.splice(index, 1);
      this.selectedEventSubject.next(this.newlyAddedItems$);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.isSelected(event)) {
      this.selectedItems$.push(event.option.value);
      this.newlyAddedItems$.push(event.option.value);
      this.selectedEventSubject.next(this.newlyAddedItems$);
    }
    this.input.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  private isSelected(event: MatAutocompleteSelectedEvent): boolean {
    return this.selectedItems$.filter(item => item.name === event.option.value.name).length === 0;
  }

  private _filter(value: string): any[] {
    if (value) {
      const filterValue = value.trim().toLowerCase();
      if (!!this.allItems$ && this.allItems$.length > 0) {
        return this.allItems$.filter(item =>
          item.name?.trim().toLowerCase().includes(filterValue)
        );
      }
    }
  }

  isRemovable(item: any): boolean {
    return this.existingItems$.filter((i) => i.name === item.name).length === 0;
  }

}
