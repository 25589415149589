import {Injectable} from '@angular/core';
import {first, firstValueFrom, Observable, Subject, tap} from 'rxjs';
import {Router} from '@angular/router';
import {AuthContext} from '../models/auth-context.model';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {ErrorService} from './error.service';
import firebase from 'firebase/compat/app';
import {ApiVersion} from '../constants/apis';
import {AuthResponse} from '../models/auth-response.model';
import {OAuthProvider} from 'firebase/auth';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractService {
  profile$: Subject<any> = new Subject<any>();
  signOut$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient,
              private authCtx: AuthContext,
              private router: Router,
              private afAuth: AngularFireAuth,
              private errorService: ErrorService) {
    super();
  }

  async signOut() {
    this.signOut$.next(true);
    this.setProfile(null);
    localStorage.removeItem('domain');
    localStorage.removeItem('gsi-token');
    localStorage.removeItem('user');
    localStorage.removeItem('signedInAt');
    await this.afAuth.signOut().then(() => localStorage.removeItem('fAuth-token'))
      .catch(() => localStorage.removeItem('fAuth-token'));
    await this.router.navigate(['sign-in']);
  }

  setProfile(profile): void {
    this.profile$.next(profile);
  }

  async signInWithGoogle(authDetails: any) {
    localStorage.setItem('gsi-token', authDetails.accessToken);
    localStorage.setItem('signedInAt', String(Math.floor(Date.now() / 1000)));
    this.retrieveGoogleUserInfo().pipe(
      tap((user) => (this.setProfile(user))),
      first()
    )
      .subscribe({
        next: () => console.log('Google Sign-in successful'),
        error: async (e: HttpErrorResponse) => {
          localStorage.removeItem('gsi-token');
          this.errorService.errorMessage$.next(e.message);
          this.errorService.refreshAuthComponent();
        },
      });
  }

  public techSignInWithGoogle(authorizationCode: string, domain: string, secretKey: string) {
    const params = new HttpParams().set('authorizationCode', authorizationCode).set('domain', domain).set('secretKey', secretKey);
    const url = this.toUrl('google', 'tech/sign-in', ApiVersion.V1);

    const options = {params};
    return this.http.post<void>(url, {}, options).subscribe({
      next: async () => await this.router.navigate(['/', 'technical-sign-in'], {queryParams: {done: 'true'}}),
      error: async (e: HttpErrorResponse) => await this.handleError(e.error.error.message.split(':')[1])
    });
  }

  async signInWithMicrosoft() {
    try {
      await this.setTenantIdInAuthInstance();
      await this.afAuth.signInWithPopup(new OAuthProvider('microsoft.com').setCustomParameters({prompt: 'select_account'}));
      localStorage.setItem('fAuth-token', firebase.auth().currentUser.uid);
      localStorage.setItem('signedInAt', String(Math.floor(Date.now() / 1000)));
      this.setProfile(firebase.auth().currentUser);
      console.log('MS Sign-in successful');
    } catch (error: any) {
      this.errorService.errorMessage$.next(error.message);
      await this.afAuth.signOut().then(() => localStorage.removeItem('fAuth-token'))
        .catch(() => localStorage.removeItem('fAuth-token'));
      this.errorService.refreshAuthComponent();
    }
  }

  async techSignInWithMicrosoft(secret: string) {
    try {
      await this.setTenantIdInAuthInstance();
      const signIn = await this.afAuth.signInWithPopup(new OAuthProvider('microsoft.com'));
      this.saveFirebaseCredentials(signIn, secret)
        .subscribe({
          next: async () => await this.router.navigate(['/', 'technical-sign-in'], {queryParams: {done: 'true'}}),
          error: async (e: HttpErrorResponse) => await this.handleError(e.error.error.message.split(':')[1])
        });
    } catch (e: any) {
      await this.handleError(e.message);
    }
  }

  async handleError(error: string) {
    this.errorService.errorMessage$.next(error);
    await this.afAuth.signOut().then(() => localStorage.removeItem('fAuth-token'))
      .catch(() => localStorage.removeItem('fAuth-token'));
    this.errorService.refreshAuthComponent();
  }

  async setTenantIdInAuthInstance(): Promise<void> {
    let response = await firstValueFrom(this.getTenantId());
    firebase.auth().tenantId = response.value || null;
  }

  getTenantId(): Observable<any> {
    const url = this.toUrl('firebase', 'tenantId', ApiVersion.V1);
    return this.http.get<any>(url);
  }

  saveFirebaseCredentials(credentials: UserCredential, secret: string): Observable<void> {
    const authenticationBody = {
      domain: localStorage['domain'],
      refreshToken: credentials.user.refreshToken,
      secretKey: secret
    };
    const url = this.toUrl('folio', 'firebase', ApiVersion.V1);
    return this.http.post<void>(url, authenticationBody);
  }

  checkUser(): Observable<AuthResponse> {
    const url = this.toUrl('folio', 'checkUser', ApiVersion.V1);
    return this.http.get<AuthResponse>(url);
  }

  retrieveGoogleUserInfo(): Observable<any> {
    return this.http.get<any>('https://openidconnect.googleapis.com/v1/userinfo');
  }
}
