import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DialogService} from '../../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationCreatorBaseComponent} from '../configuration-creator-base.component';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {firstValueFrom, Subject} from 'rxjs';
import {StorageAccountConfigModel} from '../../../models/configurations/storage-account-config.model';
import {StorageAccountService} from '../../../services/configuration/storage-account.service';
import {SimpleNameModel} from '../../../models/configurations/base/simple-name.model';
import {storageAccountConfigCreatorValidators, nameValidator} from "../../../validators/custom-validators";

@Component({
  selector: 'app-storage-account-config',
  templateUrl: './storage-account-config-creator.component.html',
  styleUrls: ['../configuration-creator-base.component.scss']
})
export class StorageAccountConfigCreatorComponent extends ConfigurationCreatorBaseComponent {

  storageAdminsForm: UntypedFormGroup;

  storageAdminList: SimpleNameModel[] = [];
  filteredStorageAdminList: SimpleNameModel[] = [];
  storageAdminsSelected: any[] = [];
  storageAdminsSelectedSubject: Subject<[]> = new Subject<[]>();
  storageAdminsClearSubject: Subject<void> = new Subject<void>();
  storageAccountSearchSubject: Subject<string> = new Subject<string>();

  isConfigSent: boolean = false;

  private gcsBucketRegex = '^gs:\\/\\/';
  private azureBlobStorageRegex = '^(([a-zA-Z0-9-]+:){4}([a-zA-Z0-9-]+)){1}$';

  constructor(configurationService: ConfigurationService,
              protected storageAccountService: StorageAccountService,
              dialogRef: MatDialogRef<StorageAccountConfigCreatorComponent>,
              formBuilder: UntypedFormBuilder,
              cdr: ChangeDetectorRef,
              dialogService: DialogService,
              translate: TranslateService,
              ngZone: NgZone) {
    super(
      dialogRef,
      formBuilder,
      cdr,
      dialogService,
      translate,
      ngZone,
      configurationService);
    super.page = 'storage-account-config';
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.initForm();
    this.initSubscriptions();

    await this.onSearchStorageAdmins();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.initSearchSubject();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }

  private initSearchSubject() {
    this.storageAccountSearchSubject
      .subscribe(async (query) => {
        if (query.length > 2) {
          this.filteredStorageAdminList = this.storageAdminList.filter(st=> st.name.includes(query));
        } else {
          this.filteredStorageAdminList = this.storageAdminList;
        }
      });
  }

  private initForm(): void {
    this.storageAdminsForm = this.formBuilder
      .group({
        library: [''],
        storageAccounts: [''],
        gcsBucket: ['', {validators: [nameValidator(new RegExp(this.gcsBucketRegex))]}],
        azureContainer: ['', {validators: [nameValidator(new RegExp(this.azureBlobStorageRegex))]}],
      }, {validators: storageAccountConfigCreatorValidators});
  }

  private initSubscriptions(): void {
    this.storageAdminsSelectedSubject
      .subscribe((selected) => {
        this.storageAdminsForm.patchValue({
          storageAccounts: selected
        });
      });
  }

  async onSearchStorageAdmins(): Promise<void> {
    try {
      await this.ngZone.run(async () => {
        const resp = await firstValueFrom(this.storageAccountService.fetchStorageAdminsList());
        this.storageAdminList = resp.storageAdmins.map(({email: name}) => ({name: name}));
        this.filteredStorageAdminList = this.storageAdminList;
      });
    } catch (error) {
      this.ngZone.run(() => {
        this.dialogService.error(error.result.error.message)
      });
    }
  }

  isGcsBucketValid(): boolean {
    return this.storageAdminsForm.controls['gcsBucket'].invalid &&
      (this.storageAdminsForm.controls['gcsBucket'].dirty || this.storageAdminsForm.controls['gcsBucket'].touched);
  }

  isAzureContainerValid(): boolean {
    return this.storageAdminsForm.controls['azureContainer'].invalid &&
      (this.storageAdminsForm.controls['azureContainer'].dirty || this.storageAdminsForm.controls['azureContainer'].touched);
  }

  create(): void {
    this.loadingSubject.next(true);
    const data: any = this.storageAdminsForm.value;
    const saConfig: StorageAccountConfigModel = {
      folioLibraryId: data.library.id,
      folioLibraryName: data.library.name,
      storageAccounts: data.storageAccounts? data.storageAccounts.map(sa => sa.name) : undefined,
      gcsBucket: data.gcsBucket,
      azureContainer: data.azureContainer
    };
    this.storageAccountService.create(saConfig).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.dialogRef.close();
        console.log(resp);
        this.configurationService.creationSubject.next(true);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogRef.close();
        if (error) {
          this.dialogService.error(
            error.error.error.message ?? error.error.message,
            `Error ${error.error.error.code} - ${error.error.error?.errors[0].reason}`
          )
        }
      }
    });
  }
}
